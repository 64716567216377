import { Injectable } from '@angular/core';
import { AuthConfig, OAuthService } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { UserService } from './user.service';
import { CognitoIdentityProviderClient, RevokeTokenCommand, RevokeTokenCommandInput, ChangePasswordCommand, ChangePasswordCommandInput, ChangePasswordCommandOutput, GlobalSignOutCommand, GlobalSignOutCommandInput, CognitoIdentityProviderServiceException } from '@aws-sdk/client-cognito-identity-provider';
import { User } from '../_models';
import { environment } from 'src/environments/environment';
import { ProfileService } from './profiles.service';
import { Router } from '@angular/router';
import { filter } from 'rxjs';
import { Angulartics2, Angulartics2Mixpanel } from 'angulartics2';

export abstract class ChangePasswordResult {
  
}

export class PasswordChangedSuccessfully extends ChangePasswordResult {
  
}

export class PasswordChangeRejected extends ChangePasswordResult {
  
  constructor(readonly reasonKey: string, readonly reasonMessage: string) {
    super();
  }
}

function buildAuthConfig(): AuthConfig {
  
  return {
    
    // Url of the Identity Provider
    issuer: environment["oidcIssuer"],
   
    // URL of the SPA to redirect the user to after login
    redirectUri: environment["loginCallbackBaseURL"] + "/login/callback",
    logoutUrl: `${environment["oidcLogoutUrl"]}?client_id=${environment["oidcClientId"]}&logout_uri=${environment["loginCallbackBaseURL"]}`,
   
    // The SPA's id. The SPA is registerd with this id at the auth-server
    clientId: environment["oidcClientId"],
   
    // set the scope for the permissions the client should request
    scope: environment["oidcScopes"],
    
    strictDiscoveryDocumentValidation: false,
    
    responseType: 'code',
    
    clearHashAfterLogin: false,
    preserveRequestedRoute: false,
  }
}

@Injectable()
export class AuthenticationService {

  constructor(
    private oauthService: OAuthService,
    private userService: UserService,
    private profileService: ProfileService,
    private router: Router,
    private analytics: Angulartics2Mixpanel,
    private angulartics: Angulartics2
    ) {
      
    this.oauthService.configure(buildAuthConfig());
    this.oauthService.tokenValidationHandler = new JwksValidationHandler();
    
    this.oauthService.events.subscribe(event => this.onTokenReceived(event));
    
    this.setupAutomaticSilentTokenRefresh();
  }
  
  private async onTokenReceived(event: any) {
    
    if (event.type === 'token_received' && this.oauthService.state) {
      console.log("token received");

      this.angulartics.eventTrack.next({ 
        action: 'Logged in',
        properties: { 
          category: 'Login'
        }
      });
      
      this.analytics.setUsername(this.getLoggedInUser()!.id!);
      const newUser = await this.profileService.createProfileIfMissing();
        
      if (newUser) {
        this.angulartics.eventTrack.next({ 
          action: 'SignedUp',
          properties: { 
            category: 'Login'
          }
        });
      }
      
      const redirect_uri = decodeURIComponent(this.oauthService.state);
      
      if (redirect_uri) {
        
        console.log('redirecting to initially requested page', redirect_uri);
        
        const parts = redirect_uri.split("?");

        let queryParamsObject: {[key: string] : string} = {};
        
        if (parts.length > 1) {
          new URLSearchParams(parts[1]).forEach((value, key) => {
            queryParamsObject[key] = value;
          });
        }         
        
        this.router.navigate([parts[0]], {
          queryParams: queryParamsObject
        });
      }
    }
  }
  
  public tryLogIn() {
    this.oauthService.loadDiscoveryDocumentAndTryLogin();
  }
  
  private setupAutomaticSilentTokenRefresh() {
    this.oauthService.setupAutomaticSilentRefresh();
    this.oauthService.events.pipe(filter(e => e.type === 'token_expires')).subscribe(e => {
    });
  }

  refreshToken() {
      this
        .oauthService
        .refreshToken()
        .then(() => {
          console.log('access token refreshed');
        })
        .catch((err: any) => {
          console.log('refresh error: ' + err);
        });
    }

  public login(returnUrl?: string) {
    
    this.angulartics.eventTrack.next({
      action: "Initiated login",
      properties: { 
        category: 'Login'
      }
    });
    
    setTimeout(() => { this.oauthService.initCodeFlow(returnUrl) }, 0);
  }
  
  public isAuthenticated(): boolean {
    return this.userService.isLoggedIn();
  }
  
  public getAccessToken(): string | undefined {
    return this.userService.getLoggedUser()?.token;
  }

  public getLoggedInUser(): User | null {
    return this.userService.getLoggedUser();
  }
  
  public hasPassword(): boolean {
    const identities = this.oauthService.getIdentityClaims()['identities'];
    
    if (identities) {
      return false;
    }
    
    return true;
  }
  
  async changePassword(oldPassword: string, newPassword: string): Promise<ChangePasswordResult> {
    const client = new CognitoIdentityProviderClient({
      region: 'eu-west-1',
      credentials: {
        accessKeyId: "dummy",
        secretAccessKey: "dummy",
      }
    });
    
    const params = {
      AccessToken: this.oauthService.getAccessToken(),
      PreviousPassword: oldPassword,
      ProposedPassword: newPassword
    } as ChangePasswordCommandInput;
    
    const command = new ChangePasswordCommand(params);
    
    try {
      await client.send(command);
      return new PasswordChangedSuccessfully();
      
    } catch (e) {
      console.log(e);
      
      if (e instanceof CognitoIdentityProviderServiceException) {
        return new PasswordChangeRejected(e.name, e.message);
      }
      
      throw e;
    }
  }
  
  async globalLogout(): Promise<void> {
    const client = new CognitoIdentityProviderClient({
      region: 'eu-west-1',
      credentials: {
        accessKeyId: "dummy",
        secretAccessKey: "dummy",
      }
    });
    
    const params = {
      AccessToken: this.oauthService.getAccessToken(),
    } as GlobalSignOutCommandInput;
    
    const command = new GlobalSignOutCommand(params);
    
    await client.send(command);
    this.oauthService.logOut(false);
  }
  
  async logout() {
    
    if (!this.isAuthenticated()) {
      console.log("Already logged out");
      return;
    }
    
    try {
      await this.invalidateRefreshToken();
    } catch (err) {
      console.warn("Failed to invalidate refresh token");
      console.warn(err);
    }
    
    this.oauthService.logOut({
      client_id: environment["oidcClientId"],
      logout_uri: environment["loginCallbackBaseURL"]
    });
    
    if (this.isAuthenticated()) {
      console.error("User still logged in");
      return;
    }
    
    console.log("User logged out");
  }
  
  private async invalidateRefreshToken() {
    
    const client = new CognitoIdentityProviderClient({
      region: 'eu-west-1',
      credentials: {
        accessKeyId: "dummy",
        secretAccessKey: "dummy",
      }
    });
    
    const params = {
      ClientId: this.oauthService.clientId!,
      Token: this.oauthService.getRefreshToken()
    } as RevokeTokenCommandInput;
    
    const command = new RevokeTokenCommand(params);
    
    return client.send(command);
  }
}
