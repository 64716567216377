import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';

export class LocationSuggestion {
  
  constructor(
    readonly reference: string,
    readonly label: string
    ) {
  }
  
}

export interface LocationSuggestionResource {
  
  reference: string;
  label: string;
}

export class Location {
  
  constructor(
      readonly id: number,
      readonly countryCode: string,
      readonly countryCodeISO2: string,
      readonly label: string,
      readonly latitude: number,
      readonly longitude: number,
      readonly municipality: string,
      readonly reference: string,
      readonly region?: string,
      readonly subRegion?: string,
      readonly postalCode?: string
  ) {}
}

export interface LocationResource {

  id: number;
  countryCode: string;
  countryCodeISO2: string;
  label: string; 
  latitude: number;
  longitude: number;
  municipality: string;
  reference: string;
  region: string | null;
  subRegion: string | null;
  postalCode: string | null;
}

@Injectable()
export class LocationsService {

  constructor(
      protected http: HttpClient) {
  }
  
  public async create(reference: string): Promise<Location> {
    return firstValueFrom(this.http.post<LocationResource>(`${environment.apiContext}/locations`, { reference: reference })
      .pipe(map((resource: LocationResource) => LocationsService.resourceToEntity(resource))));
  }

  public async listSuggestions(alpha3CountryCode: string, partialLabel: string): Promise<LocationSuggestion[]> {
    
    return firstValueFrom(this.http.get<LocationSuggestionResource[]>(`${environment.apiContext}/locations/suggestions?alpha3CountryCode=${alpha3CountryCode}&partialLabel=${partialLabel}`)
      .pipe(
        map((resources: LocationSuggestionResource[]) => resources.map(resource => this.suggestionResourceToEntity(resource)))
      ));
  }
  
  private suggestionResourceToEntity(resource: LocationSuggestionResource): LocationSuggestion {
    return new LocationSuggestion(
          resource.reference,
          resource.label)
  }
  
  public static entityToResource(location: Location): LocationResource {
    return {
        id: location.id,
        countryCode: location.countryCode,
        countryCodeISO2: location.countryCodeISO2,
        label: location.label,
        latitude: location.latitude,
        longitude: location.longitude,
        municipality: location.municipality,
        reference: location.reference,
        region: location.region || null,
        subRegion: location.subRegion || null,
        postalCode: location.postalCode || null
    }
  }
  
  public static resourceToEntity(resource: LocationResource): Location {
    return new Location(
          resource.id,
          resource.countryCode,
          resource.countryCodeISO2,
          resource.label,
          resource.latitude,
          resource.longitude,
          resource.municipality,
          resource.reference,
          resource.region ? resource.region : undefined,
          resource.subRegion ? resource.subRegion : undefined,
          resource.postalCode ? resource.postalCode : undefined);
  }
}

  