import { NgModule, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterModule, RouterStateSnapshot, Routes } from '@angular/router';
import { AuthenticationService, GMailService } from './_services';
import { AuthGuard } from './_guards';

const signup: CanActivateFn =
    (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
      
      if (!inject(AuthenticationService).isAuthenticated()) {
        inject(Router).navigate(['/signup']);
      }
      
      return true;
    };
    
const AccessToInboxAlreadyGranted: CanActivateFn =
  async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> => {
    
    const router = inject(Router);
    
    if ('confirmGMailIntegration' in route.queryParams) {
      return true;
    }
    
    
    if (inject(AuthenticationService).isAuthenticated()) {
      
      const issuedAt = await inject(GMailService).getIntegration();
    
      if (issuedAt) {
        router.navigate(['/items-to-sell']);
        return false;
      }
      
    }
    
    return true;
  };

const routes: Routes = [
  {path: '', loadChildren: () => import('./modules/search/search.module').then(m => m.SearchModule) },
  {path: 'privacy', loadChildren: () => import('./modules/privacy/privacy.module').then(m => m.PrivacyModule)},
  {path: 'tac', loadChildren: () => import('./modules/tac/tac.module').then(m => m.TACModule)},
  {path: 'items-to-sell', loadChildren: () => import('./modules/items-to-sell/items-to-sell.module').then(m => m.ItemsToSellModule), canActivate: [AuthGuard]},
  {path: 'edit-listing', loadChildren: () => import('./modules/edit-listing/edit-listing.module').then(m => m.EditListingModule)},
  {path: 'create-listing', loadChildren: () => import('./modules/create-listing/create-listing.module').then(m => m.CreateListingModule), canActivate: [AuthGuard]},
  {path: 'view-listing', loadChildren: () => import('./modules/view-listing/view-listing.module').then(m => m.ViewListingModule)},
  {path: 'dashboard', loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule), canActivate: [AuthGuard]},
  {path: 'login', loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule)},
  {path: 'landing', loadChildren: () => import('./modules/landing/landing.module').then(m => m.LandingModule)},
  {path: 'signup', loadChildren: () => import('./modules/signup/signup.module').then(m => m.SignupModule)},
  {path: 'access-inbox', loadChildren: () => import('./modules/access-inbox/access-inbox.module').then(m => m.AccessInboxModule), canActivate: [AccessToInboxAlreadyGranted]},
  {path: 'admin', loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule), canActivate: [AuthGuard]},
  {path: 'account', loadChildren: () => import('./modules/account/account.module').then(m => m.AccountModule), canActivate: [AuthGuard]},
  {path: 'about', loadChildren: () => import('./modules/global-warming/global-warming.module').then(m => m.GlobalWarmingModule)},
  {path: 'contact', loadChildren: () => import('./modules/contact/contact.module').then(m => m.ContactModule)},
  {path: 'chats', loadChildren: () => import('./modules/listing-chats/listing-chats.module').then(m => m.ListingChatsModule), canActivate: [AuthGuard]},
  {path: 'why-trust', loadChildren: () => import('./modules/why-trust/why-trust.module').then(m => m.WhyTrustModule)},
  {path: 'snap-and-sell', loadChildren: () => import('./modules/snap-and-sell/snap-and-sell.module').then(m => m.SnapAndSellModule)},
  {path: 'listing-created', loadChildren: () => import('./modules/listing-created/listing-created.module').then(m => m.ListingCreatedModule), canActivate: [AuthGuard]},
  {path: 'select-listing-flow', loadChildren: () => import('./modules/select-listing-flow/select-listing-flow.module').then(m => m.SelectListingFlowModule)},
  {path: 'learn-more', loadChildren: () => import('./modules/learn-more/learn-more.module').then(m => m.LearnMoreModule)},
  {path: 'cookie-policy', loadChildren: () => import('./modules/cookie-policy/cookie-policy.module').then(m => m.CookiePolicyModule)},
  {path: 'snap-and-sell-ad', loadChildren: () => import('./modules/snap-and-sell-ad/snap-and-sell-ad.module').then(m => m.SnapAndSellAdModule)},
  {path: 'campaign', loadChildren: () => import('./modules/campaign/campaign.module').then(m => m.CampaignModule)},
  {path: '**', redirectTo: '/'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking',
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
