import { APP_ID, Injectable, InjectionToken, Injector, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { MatListModule } from '@angular/material/list';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { OAuthModule } from 'angular-oauth2-oidc';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';

import { UserService, AlertService, AuthenticationService, GMailService, ItemsToSellService, PurchaseService, BetaSignUpService, ParsingService } from './_services'

import { AuthGuard } from './_guards';
import { DefaultAPIErrorHandler, JwtInterceptor, LanguageInterceptor } from './_interceptors';
import { FooterComponent } from './components/footer/footer.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { PictureService } from './_services/picture.service';
import { MatDividerModule } from '@angular/material/divider';
import { ProfileService } from './_services/profiles.service';
import { LocationsService } from './_services/locations.service';

import { Angulartics2Module } from 'angulartics2';
import { FeedbackService } from './_services/feedback.service';
import { SHARE_BUTTONS_CONFIG, SharerMethod } from 'ngx-sharebuttons';
import { DateAdapter, MAT_DATE_FORMATS, MAT_NATIVE_DATE_FORMATS, NativeDateAdapter } from '@angular/material/core';
import { ChatService } from './_services/chat.service';
import { MatBadgeModule } from '@angular/material/badge';
import { PortalModule } from '@angular/cdk/portal';
import { ListingsSearchIndexService } from './_services/listings-search-index.service';
import { WebViewService } from './_services/webview.service';
import { SidenavService } from './_services/sidenav.service';
import { MatDialogModule } from '@angular/material/dialog';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { ErrorHandler } from "@angular/core";
import { RUMService } from './_services/rum.service';
import { AnalyticsService } from './_services/analytics.service';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { ConsentSheet } from './components/consent/consent.component';
import { ConsentService } from './_services/consent.service';
import { environment } from 'src/environments/environment';
import { MatSelectCountryLangToken } from '@angular-material-extensions/select-country';
import { LanguageService } from './_services/language.service';
import { SharedModule } from './modules/shared/shared.module';
import { WebViewPromptModule } from './modules/shared/webview-prompt/webivew-prompt.module';
import { LanguageModule } from './modules/shared/language/language.module';
import { IMAGE_LOADER, ImageLoaderConfig } from '@angular/common';
import { enGB, et } from 'date-fns/locale'
import { MatSidenavModule } from '@angular/material/sidenav';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  
  constructor(private injector: Injector) { } 
  
  handleError(error: any) {
    
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (chunkFailedMessage.test(error.message)) {
      if(confirm("New version available. Load New Version?")) {
        window.location.reload();
      }
    }
    
    this.injector.get(RUMService).handleError(error);
    console.error(error);
  }
}

export const DATE_FNS_LOCALE = new InjectionToken<Locale>('DATE_FNS_LOCALE');

export function mapLocaleIdToDateFns(localeId: string): Locale {
  const localeMap: Record<string, Locale> = {
    'en-US': enGB,
    'et': et
  };

  return localeMap[localeId];
}

@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    FooterComponent,
    ConsentSheet,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NoopAnimationsModule,
    HttpClientModule,
    MatSnackBarModule,
    MatToolbarModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    MatGridListModule,
    MatDividerModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatDialogModule,
    PortalModule,
    SharedModule,
    MatSidenavModule,
    MatListModule,
    LanguageModule,
    WebViewPromptModule,
    OAuthModule.forRoot(),
    Angulartics2Module.forRoot({
      gst: {
        trackingIds: environment.gtag.trackingIds,
        anonymizeIp: true
      }
    })
  ],
  providers: [
	  AuthenticationService,
    AlertService,
    UserService,
    GMailService,
    ItemsToSellService,
    PurchaseService,
    BetaSignUpService,
    ParsingService,
    PictureService,
    ProfileService,
    LocationsService,
    FeedbackService,
    ListingsSearchIndexService,
    ChatService,
    WebViewService,
    AnalyticsService,
    ConsentService,
    RUMService,
    LanguageService,
    SidenavService,
    AuthGuard,
    { provide: APP_ID, useValue: 'serverApp' },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: DefaultAPIErrorHandler, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LanguageInterceptor, multi: true },
    { provide: SHARE_BUTTONS_CONFIG, useValue: {sharerMethod: SharerMethod.Window, sharerTarget: 'newwindow'}},
    { provide: DateAdapter, useClass: NativeDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: MAT_NATIVE_DATE_FORMATS },
    { provide: STEPPER_GLOBAL_OPTIONS, useValue: { displayDefaultIndicatorType: false }},
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: MatSelectCountryLangToken, useValue: LOCALE_ID },
    { provide: DATE_FNS_LOCALE, useFactory: (localeId: string) => mapLocaleIdToDateFns(localeId), deps: [LOCALE_ID] },
    {
      provide: IMAGE_LOADER,
      useValue: (config: ImageLoaderConfig) => {
        const fileName = config.width ? config.src.replace('.webp', '_' + config.width + ".webp") : config.src;
        return `${environment.assetsBaseURL}${fileName}`;
      },
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
