import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component } from '@angular/core';
import { format } from 'date-fns'
import { MaterialContent } from '../page/material-content.component';

@Component({
  selector: 'footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent extends MaterialContent {

  constructor(breakpointObserver: BreakpointObserver) {
    super(breakpointObserver, true);
  } 
  
  get currentYear() {
    return format(new Date(), 'yyyy');
  }
}
