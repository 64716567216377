import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Angulartics2Module } from 'angulartics2';
import { TextWithStripeComponent } from './components/text-with-stripe/text-with-stripe.component';
import { AssetURLPipe } from './_pipes/assets-url.pipe';

@NgModule({
    imports: [
        CommonModule,
        Angulartics2Module,
     ],
    declarations: [
        TextWithStripeComponent,
        AssetURLPipe
    ],
    exports: [
        TextWithStripeComponent,
        AssetURLPipe
    ]
})
export class SharedModule {}