import {Injectable} from '@angular/core';
import {User} from '../_models/user';
import {HttpClient} from '@angular/common/http';
import { OAuthService } from 'angular-oauth2-oidc';

@Injectable()
export class UserService {

  constructor(
      protected http: HttpClient, 
      private oauthService: OAuthService) {
  }

  isLoggedIn(): boolean {
    return this.oauthService.hasValidAccessToken();
  }
  
  private getOICDLoggedInUser() {
    
    const claims = this.oauthService.getIdentityClaims();
    
    if (!claims) {
      return null;
    }
    
    const user = new User();
    user.id = claims["sub"];
    user.email = claims["email"];
    user.name = claims["name"];
    user.token = this.oauthService.getAccessToken();
    user.roles = claims["cognito:groups"];
    
    return user;
  }
  
  /**
   *
   * @return {} null if not exist
   */
  getLoggedUser(): User | null {
    
    let currentUser = this.getOICDLoggedInUser();
 
    if (currentUser == null) {
      return null;
    }
      
    return Object.assign(new User(), currentUser);
  }

  hasUserRole(role: string): boolean {
    
    const loggedInUser = this.getLoggedUser();
    
    if (!loggedInUser) {
      return false;
    }
    
    return loggedInUser.hasRole(role);
  }

  writeUserToStorage(user: any) {
    localStorage.setItem('currentUser', JSON.stringify(user));
  }
}

	