import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpContextToken } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import {AuthenticationService} from '../_services';
import { map } from 'rxjs';
import { AlertService } from '../_services/alert.service';

/**
 * Interceptor of all http requests
 */
@Injectable()
export class DefaultAPIErrorHandler implements HttpInterceptor {
  
  public static HANDLED_STATUS_CODES = new HttpContextToken<number[]>(() => []);
  
  constructor(private authenticationService: AuthenticationService,
              private alertService: AlertService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
    const ignoredStatuses = request.context.get(DefaultAPIErrorHandler.HANDLED_STATUS_CODES);
    
    const catchErrorCallback = (err: any, caught: Observable<any>) => {
      
      if (ignoredStatuses?.includes(err.status)) {
        // rethrow error to be catched locally
        return throwError(() => err);
      }
      
      // this.loaderHelper.end();
      // Bad request
      if (err.status === 400) {
        this.showApiError(err);
        return throwError(() => err);
      }
      if (err.status === 401) {
        console.log("Triggering login based on response from backend");
        // auto logout if 401 response returned from api
        this.authenticationService.logout();
        return throwError(() => err);
      }

      if (err.status === 404) {
        console.log("Not found")
      }

      if (err.status === 403) {
        this.showMessageIfNeeded({message: $localize `Forbidden action`});
        return throwError(() => err);
      }

      if (err.status === 0) {
        console.log("Can't connect to backend");
      }
      
      this.showMessageIfNeeded({message: $localize `Something went wrong`});

      return throwError(() => err);
    };

    return next.handle(request).pipe(
        map((event: any) => {
          this.showMessageIfNeeded(event.body);
          return event;
        }),
        catchError((err, caught) => catchErrorCallback(err, caught))
    );
  }

  showApiError(body: any) {
    if (body && body.error && body.error.length > 0 && body.error[0].defaultMessage) {
      let errorMessage = body.error[0].defaultMessage;
      if (body.error[0].field) {
        errorMessage = body.error[0].field + " " + errorMessage;
      }
      this.alertService.showError(errorMessage); //TODO: show all messages, translate based on code
    }
  }
  
  showMessageIfNeeded(body: any) {
    
    if (!body) {
      return;
    }
    
    const messages = (body.error ? body.error.messages : null)
      || (body.error ? body.error.message : null);
      
    if (!messages || (Array.isArray(messages) && messages.length === 0)) {
      return;
    }
    
    if (body.success) {
      this.alertService.showSuccess(messages);
    } else {
      this.alertService.showError(messages);
    }
  }
}
