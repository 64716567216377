import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';

@Injectable()
export class ListingsSearchIndexService {
  
  constructor(
      protected http: HttpClient) {
  }
  
  async create(): Promise<void> {
    
    return await firstValueFrom(this.http.post<void>(`${environment.apiContext}/listings-search-indexes`, {}));
  }
  
  async startReindex(newIndexName: string): Promise<void> {
    
    return await firstValueFrom(this.http.post<void>(`${environment.apiContext}/listings-search-indexes/${newIndexName}`, {}));
  }
  
  async finishReindex(oldIndexName: string): Promise<void> {
    
    return await firstValueFrom(this.http.delete<void>(`${environment.apiContext}/listings-search-indexes/${oldIndexName}`, {}));
  }
  
  async deleteItem(id: number): Promise<void> {
    
    return await firstValueFrom(this.http.delete<void>(`${environment.apiContext}/listings-search-indexes/current/items/${id}`, {}));
  }
}

	