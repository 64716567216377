import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';

@Pipe({
  name: 'assetUrl'
})
export class AssetURLPipe implements PipeTransform {
  
  transform(value: string): string {
    return environment.assetsBaseURL + value;
  }
}