import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()
export class LanguageInterceptor implements HttpInterceptor {

  constructor(
    @Inject(LOCALE_ID) private locale: string
  ) {
    console.log("API requests will have Accept-Language header set to " + locale);
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (!request.url.startsWith(environment["apiContext"])) {
      return next.handle(request);
    }

    request = request.clone({
      headers: request.headers.set('Accept-Language', this.locale)
    });

    return next.handle(request);
  }
}
