import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()
export class FeedbackService {

  constructor(
      protected http: HttpClient) {
  }

  async save(message: string): Promise<void> {
    
    return firstValueFrom(this.http.post<void>(`${environment.apiContext}/feedbacks`, { message: message }));
  }
}

  