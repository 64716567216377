import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpClient, HttpContext, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, firstValueFrom } from 'rxjs';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { getDay, parseISO } from 'date-fns';
import { Money } from '@dintero/money';
import { Location, LocationResource, LocationsService } from './locations.service';
import { DefaultAPIErrorHandler } from '../_interceptors';
import { TransferState, makeStateKey } from '@angular/core';
import { isPlatformServer } from '@angular/common';

export enum ItemToSellState {
  DRAFT = "DRAFT", LISTED = "LISTED", READY_FOR_LISTING = "READY_FOR_LISTING"
}

export enum ItemToSellSource {
  MANUAL = "MANUAL", MAILBOX = "MAILBOX", SNAP_AND_SELL = "SNAP_AND_SELL"
}

export class Category {
  
  constructor(readonly code: string, readonly name: string) {}
}

export class ItemToSellStatistics {
  constructor(readonly allCategories: Category[]) {}
}

export class ItemToSellList {
  constructor(readonly items: ItemToSell[], readonly statistics: ItemToSellStatistics) {}
}

export class ItemToSell {
  
  private static TITLE_SPLIT_REGEX = /,|\||\(/;
  
  private static FILLER_ICONS_PER_CATEGORY = new Map<string, string>([
    ['Automotive', 'directions_car'],
    ['Beauty', 'spa'],
    ['Clothing & shoes', 'checkroom'],
    ['Electronics', 'phone_iphone'],
    ['Furnishings & appliances attached to the house', 'water_heater'],
    ['Furniture', 'chair'],
    ['Generic', 'shopping_bag'],
    ['Generic home', 'curtains'],
    ['Home appliances', 'kitchen'],
    ['Jewellery', 'diamond'],
    ['Sports', 'sports_soccer'],
    //new
    ['Fashion & Accessories', 'checkroom'],
    ['Home & Furniture', 'chair'],
    ['Food & Kitchen', 'kitchen'],
    ['Health', 'spa'],
    ['Kids', 'toys'],
    ['Books', 'menu_book'],
    ['Car related', 'directions_car'],
    ['Other', 'shopping_bag'],
  ]);
  
  public static TOP_LEVEL_CATEGORIES = [
    
    new Category("537", $localize `Baby & Toddler`),
    new Category("141", $localize `Cameras & Optics`),
    new Category("166", $localize `Clothing & Accessories`),
    new Category("222", $localize `Electronics`),
    new Category("436", $localize `Furniture`),
    new Category("469", $localize `Health & Beauty`),
    new Category("536", $localize `Home & Garden`),
    new Category("783", $localize `Media`),
    new Category("988", $localize `Sporting Goods`),
    new Category("1239", $localize `Toys & Games`),
    new Category("888", $localize `Vehicles & Parts`),
    new Category("0", $localize `Other`),
  ]
  
  private _title: string;
  private _resaleValue?: Money;
  private _description: string | null;
  private _price?: Money;
  private _priceEUR?: Money;
  
  constructor(
    readonly id: number,
    title: string,
    description: string | null,
    public pictures: ItemToSellPicture[],
    public state: ItemToSellState,
    readonly categories: string[],
    readonly structuredCategories: Category[],
    readonly listingTimestamp: Date | null,
    public ownerNickName: string | null,
    public location: Location,
    readonly source: ItemToSellSource,
    readonly owner?: string,
    resaleValue?: Money,
    readonly purchasedInShop?: string,
    public condition?: number,
    readonly purchasePriceEUR?: Money,
    readonly dateOfPurchase?: Date,
    readonly createdTime?: Date,
    price?: Money,
    priceEUR?: Money,
    public originalTitle?: string,
    public originalDescription?: string
    ) {
      
    this._title = title;
    this._resaleValue = resaleValue;
    this._description = description;
    this._price = price;
    this._priceEUR = priceEUR;
  }
  
  set price(price: Money | undefined) {
    
    if (price && price.currency() !== 'EUR') {
      throw new Error("Wrong currency");
    }
    
    this._price = price;
    this._priceEUR = price;
  }
  
  get priceEUR(): Money | undefined {
    return this._priceEUR;
  }
  
  get price(): Money | undefined {
    return this._price;
  }
  
  get category(): string | undefined {
    
    if (!this.categories || this.categories.length === 0) {
      return undefined;
    }
    
    return this.categories[0];
  }
    
  get title(): string {
    
    if (this._title.length > 50) {
      return this._title.split(ItemToSell.TITLE_SPLIT_REGEX)[0];
    }
    
    return this._title;
  }
  
  set title(title: string) {
    this._title = title;
  }
  
  get description(): string | null {
    
    if (this._title.length > 50 && !this._description) {
      const parts = this._title.split(ItemToSell.TITLE_SPLIT_REGEX);
    
      if (parts.length < 2) {
        return null;
      }
      
      parts.shift();
      
      return parts.reduce((description, part) => description + "," + part);
    }
    
    return this._description;
  }
  
  set description(description: string | null) {
    this._description = description;
  }
  
  get weekdayOfPurchase(): number | undefined {
    
    if (!this.dateOfPurchase) {
      return undefined
    }
    
    return (getDay(this.dateOfPurchase) + 6) % 7;
  }
  
  get resaleValue(): Money | undefined {
    
    return this._resaleValue
  }
  
  get topLevelCategory(): string | null {
    
    if (this.categories.length === 0) {
      return null;
    } 
    
    return this.categories[0].split("/")[0];
  }
  
  get lowestLevelCategories(): string[] {
    return this.categories.map(category => {
      const parts = category.split("/");
      return parts[parts.length - 1];
    });
  }
  
  get fillerIconName(): string {
    
    const category = this.categories.length > 0 ? this.categories[0].split("/")[0] : (this.category ? this.category : 'Generic');
    
    return ItemToSell.FILLER_ICONS_PER_CATEGORY.get(category) || 'styler';
  }
}

export class ItemToSellPicture {
  
  constructor(readonly key: string, public order: number) {}
  
  get thumbnailKey() {
    return this.key.replace(".original", "").replace(".jpg", ".thumb.jpg");
  }
}

export interface CreateItemToSellResource {
  title?: string;
  description?: string | null;
  dateOfPurchase?: string;
  price: {
    amount: number;
    currency: string;
  };
  purchasedInShop?: string | null;
  condition?: number; //TODO: why optional?
  categories?: string[];
  categoryCodes?: string[];
  pictures: ItemToSellPictureResource[];
  state?: string;
  source: ItemToSellSource;
  persist?: boolean;
}

export interface CategoryResource {
  code: string;
  name: string;
}

export interface ResourceList<T> {
  
  resources: [T];
  statistics: any;
}

export interface ItemToSellResource {
  price?: {
    amount: number;
    currency: string;
  };
  condition?: number; //TODO: why optional?
  pictures: ItemToSellPictureResource[];
  state?: string;
  source: ItemToSellSource
  id: number;
  title: string;
  description: string | null;
  originalTitle: string | null;
  originalDescription: string | null;
  dateOfPurchase: string | null;
  purchasedInShop: string | null;
  categories: string[];
  structuredCategories: CategoryResource[];
  purchasePriceEUR?: {
    amount: number;
    currency: string;
  } | null;
  priceEUR?: {
    amount: number;
    currency: string;
  };
  listingTimestamp: string | null;
  createdTime: string | null;
  ownerNickName: string | null;
  location: LocationResource;
  owner: string | null;
}

export interface ItemToSellWithResaleValue {
  itemToSell: ItemToSellResource;
  resaleValue?: {
    amount: number;
    currency: string;
  }
}

export interface ItemToSellPictureResource {
  key: string;
  order: number;
}

export interface ItemToSellFilter {
  q?: string,
  categoryCode?: string,
  owner?: string[]
  limit?: number;
  offset?: number;
  sort?: string;
  hasPicture?: boolean;
  state?: ItemToSellState[];
}

@Injectable()
export class ItemsToSellService {

  constructor(
      protected http: HttpClient,
      private transferState: TransferState,
      @Inject(PLATFORM_ID) private platformId: any) {
  }

  public async updateAsync(item: ItemToSell): Promise<ItemToSell> {
    
    const update = {
      title: item.title,
      description: item.description,
      condition: item.condition,
      price: {
        amount: item.price!.toNumber(),
        currency: item.price!.currency()
      },
      pictures: item.pictures.map(picture => {
        return {key: picture.key, order: picture.order};
      }),
      state: item.state
    }
    
    return firstValueFrom(this.http.patch<ItemToSellResource>(`${environment.apiContext}/items-to-sell/${item.id}`, update)      
      .pipe(map((resource: ItemToSellResource) => ItemsToSellService.resourceToEntity(resource))));
  }
  
  public async create(create: CreateItemToSellResource): Promise<ItemToSell> {
    return firstValueFrom(this.http.post<ItemToSellResource>(`${environment.apiContext}/items-to-sell`, create)
      .pipe(map((resource: ItemToSellResource) => ItemsToSellService.resourceToEntity(resource))));
  }
  
  public async triggerCategoryCodesMigration(): Promise<void> {
    return firstValueFrom(this.http.post<void>(`${environment.apiContext}/category-codes-migration`, {}));
  }
  
  public listWithResaleValue(filter?: ItemToSellFilter): Observable<ItemToSell[]> {
    
    let queryParams = new HttpParams();
    
    if (filter?.q) {
      queryParams = queryParams.set("q", filter.q);
    }

    if (filter?.owner) {
      queryParams = queryParams.appendAll({"owner": filter.owner});
    }
    
    if (filter?.state) {
      queryParams = queryParams.appendAll({"state": filter.state});
    }
    
    if (filter?.limit) {
      queryParams = queryParams.set("limit", filter.limit);
    }
    
    if (filter?.offset) {
      queryParams = queryParams.set("offset", filter.offset);
    }
    
    if (filter?.sort) {
      queryParams = queryParams.set("sort", filter.sort);
    }
    
    if (filter?.hasPicture) {
      queryParams = queryParams.set("hasPicture", filter.hasPicture);
    }
    
    return this.http.get<ItemToSellWithResaleValue[]>(`${environment.apiContext}/items-to-sell-with-resale-price`, { params: queryParams })
      .pipe(
        map((resources: ItemToSellWithResaleValue[]) => resources.map(resource => ItemsToSellService.resourceToEntity(
          resource.itemToSell, 
          resource.resaleValue ? Money.of(resource.resaleValue.amount, resource.resaleValue.currency) : undefined)))
      );
  }
  
  public async find(id: number): Promise<ItemToSell | null> {
    
    const stateKey = makeStateKey<ItemToSellResource>("item-" + id);
    
    if (this.transferState.hasKey(stateKey)) {
      const resource = this.transferState.get(stateKey, null)!;
      return ItemsToSellService.resourceToEntity(resource);
    }
    
    const context = new HttpContext().set(DefaultAPIErrorHandler.HANDLED_STATUS_CODES, [404, 403]);
    
    try {
      
      const resource = await firstValueFrom(this.http.get<ItemToSellResource>(`${environment.apiContext}/items-to-sell/${id}`, { context: context }));
      const item = ItemsToSellService.resourceToEntity(resource);
      
      if (isPlatformServer(this.platformId)) {
        this.transferState.set(stateKey, resource);
      }
      
      return item;
      
    } catch (error) {
      
      if (error instanceof HttpErrorResponse && (error.status === 404 || error.status === 403)) {
        return null;
      }
      
      throw error;
    }
  }

  /**
   * @deprecated
   */
  public async list(filter?: ItemToSellFilter): Promise<ItemToSell[]> {
    
    let queryParams = new HttpParams();
    
    if (filter?.q) {
      queryParams = queryParams.set("q", filter.q);
    }
    
    if (filter?.categoryCode) {
      queryParams = queryParams.set("categoryCode", filter.categoryCode);
    }

    if (filter?.owner) {
      queryParams = queryParams.appendAll({"owner": filter.owner});
    }    
    
    if (filter?.state) {
      queryParams = queryParams.appendAll({"state": filter.state});
    }
    
    if (filter?.limit) {
      queryParams = queryParams.set("limit", filter.limit);
    }
    
    if (filter?.offset) {
      queryParams = queryParams.set("offset", filter.offset);
    }
    
    if (filter?.sort) {
      queryParams = queryParams.set("sort", filter.sort);
    }
    
    if (filter?.hasPicture) {
      queryParams = queryParams.set("hasPicture", filter.hasPicture);
    }
    
    return firstValueFrom(this.http.get<ItemToSellResource[]>(`${environment.apiContext}/items-to-sell`, { params: queryParams })
      .pipe(
        map((resources: ItemToSellResource[]) => resources.map(resource => ItemsToSellService.resourceToEntity(resource)))
      ));
  }
  
  public async listV2(filter?: ItemToSellFilter): Promise<ItemToSellList> {

    if (!environment.searchByCategory) {
      const items = await this.list(filter);
      
      return new ItemToSellList(items, new ItemToSellStatistics([]));
    }

    let queryParams = new HttpParams();
    
    if (filter?.q) {
      queryParams = queryParams.set("q", filter.q);
    }
    
    if (filter?.categoryCode) {
      queryParams = queryParams.set("categoryCode", filter.categoryCode);
    }

    if (filter?.owner) {
      queryParams = queryParams.appendAll({"owner": filter.owner});
    }    
    
    if (filter?.state) {
      queryParams = queryParams.appendAll({"state": filter.state});
    }
    
    if (filter?.limit) {
      queryParams = queryParams.set("limit", filter.limit);
    }
    
    if (filter?.offset) {
      queryParams = queryParams.set("offset", filter.offset);
    }
    
    if (filter?.sort) {
      queryParams = queryParams.set("sort", filter.sort);
    }
    
    if (filter?.hasPicture) {
      queryParams = queryParams.set("hasPicture", filter.hasPicture);
    }
    
    return firstValueFrom(this.http.get<ResourceList<ItemToSellResource>>(`${environment.apiContext}/v2/items-to-sell`, { params: queryParams })
      .pipe(
        map((resource: ResourceList<ItemToSellResource>) => {
          return new ItemToSellList(
            resource.resources.map(itemResource => ItemsToSellService.resourceToEntity(itemResource)),
            new ItemToSellStatistics(
              resource.statistics.allCategories.map((categoryResource: any) => new Category(categoryResource.code, categoryResource.name))
            )
          );
        })
      ));
  }
  
  public async delete(id: number): Promise<void> {
    
    await firstValueFrom(this.http.delete(`${environment.apiContext}/items-to-sell/${id}`));
  }
  
  public persistLocally(itemToSell: ItemToSell) {
    sessionStorage.setItem("listx_local-item", JSON.stringify(this.entityToResource(itemToSell)));
  }
  
  public getLocalItem(): ItemToSell | null {
    
    const localItem = sessionStorage.getItem("listx_local-item");
    
    if (!localItem) {
      return null;
    }
    
    return ItemsToSellService.resourceToEntity(JSON.parse(localItem));
  }
  
  private entityToResource(item: ItemToSell): ItemToSellResource {
    
    return {
      price: item.price ? {
        amount: item.price!.toNumber(),
        currency: item.price!.currency()
      } : undefined,
      condition: item.condition,
      pictures: item.pictures.map(picture => {
        return {
          key: picture.key,
          order: picture.order
        }
      }),
      state: item.state,
      source: item.source,
      id: item.id,
      title: item.title,
      description: item.description,
      originalTitle: item.originalTitle || null,
      originalDescription: item.originalDescription || null,
      dateOfPurchase: item.dateOfPurchase ? item.dateOfPurchase.toISOString() : null,
      purchasedInShop: item.purchasedInShop || null,
      categories: item.categories,
      structuredCategories: item.structuredCategories,
      purchasePriceEUR: item.purchasePriceEUR ? {
        amount: item.purchasePriceEUR!.toNumber(),
        currency: item.purchasePriceEUR!.currency()
      } : undefined,
      priceEUR: item.priceEUR ? {
        amount: item.priceEUR!.toNumber(),
        currency: item.priceEUR!.currency()
      } : undefined,
      listingTimestamp: item.listingTimestamp ? item.listingTimestamp.toISOString() : null,
      createdTime: item.createdTime ? item.createdTime.toISOString() : null,
      ownerNickName: item.ownerNickName,
      location: LocationsService.entityToResource(item.location),
      owner: item.owner || null
    };
  }
  
  public static resourceToEntity(resource: ItemToSellResource, resalePrice?: Money): ItemToSell {
    
    return new ItemToSell(
          resource.id,
          resource.title,
          resource.description,
          resource.pictures.map(pictureResource => new ItemToSellPicture(pictureResource.key, pictureResource.order)).sort((a, b) => a.order - b.order),
          ItemToSellState[resource.state as keyof typeof ItemToSellState],
          resource.categories?.length ? resource.categories! : [],
          resource.structuredCategories.map(category => new Category(category.code, category.name)),
          resource.listingTimestamp ? parseISO(resource.listingTimestamp) : null,
          resource.ownerNickName,
          LocationsService.resourceToEntity(resource.location),
          resource.source,
          resource.owner || undefined,
          resalePrice,
          resource.purchasedInShop || undefined,
          resource.condition || undefined,
          resource.purchasePriceEUR ? Money.of(resource.purchasePriceEUR.amount, resource.purchasePriceEUR.currency) : undefined,
          resource.dateOfPurchase ? parseISO(resource.dateOfPurchase) : undefined,
          resource.createdTime ? parseISO(resource.createdTime) : undefined,
          resource.price ? Money.of(resource.price.amount, resource.price.currency) : undefined,
          resource.priceEUR ? Money.of(resource.priceEUR.amount, resource.priceEUR.currency) : undefined,
          resource.originalTitle || undefined,
          resource.originalDescription || undefined
        )
  }
}

  