<div class="footer-container">
  <div [ngStyle]="gridStyles">
    <div class="mdc-layout-grid">
      <div class="mdc-layout-grid__inner">
        <div class="left mdc-layout-grid__cell mdc-layout-grid__cell--span-8">
          <div class="footer">
            <div><a routerLink="why-trust" i18n>Why Trust Us</a></div>
            <div><a routerLink="privacy" i18n>Privacy & Data Processing Rules</a></div>
            <div><a routerLink="tac" i18n>Terms and conditions</a></div>
            <div>support@listx.ai</div>
          </div>
        </div>
        <div class="left mdc-layout-grid__cell mdc-layout-grid__cell--span-4">
          <div class="footer">
            <div><a routerLink="learn-more" i18n>Learn how it works</a></div>
            <div><a routerLink="about" i18n>About</a></div>
            <div><a routerLink="snap-and-sell-ad" i18n>Snap&Sell</a></div>
            <div><a routerLink="campaign" i18n>Campaigns</a></div>
          </div>
        </div>
        <div class="left mdc-layout-grid__cell mdc-layout-grid__cell--span-12">
          <div class="footer">
            <p i18n>Copyright© {{currentYear}} Listx OÜ. All rights reserved</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>