import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { Location, LocationResource, LocationsService } from './locations.service';
import { ItemsToSellService } from './items-to-sell.service';

export class Profile {
  
  constructor(
    public location: Location,
    public exposeNonListedItems?: boolean,
    public username?: string
    ) {
  }
}

export class PersistedProfile extends Profile {
  
  constructor(
    readonly id: number,
    username: string,
    readonly identity: string,
    location: Location,
    exposeNonListedItems?: boolean,
    ) {
      super(location, exposeNonListedItems, username);
  }
  
}

export interface CreateProfileResource {
  username?: string;
  location?: { reference: string };
  exposeNonListedItems?: boolean;
}

export interface ProfileResource {
  
  id: number;
  username: string;
  identity: string;
  location: LocationResource;
  exposeNonListedItems: boolean | null;
}

@Injectable()
export class ProfileService {

  constructor(
      protected http: HttpClient,
      private itemsToSellService: ItemsToSellService) {
  }

  public async create(): Promise<PersistedProfile> {
    
    const localProfile = this.getLocalProfile();

    const crateRequest = {
      username: localProfile?.username,
      location: localProfile?.location
    }    
    
    const profile = await firstValueFrom(this.http.post<ProfileResource>(`${environment.apiContext}/profiles`, crateRequest)
      .pipe(
        map((resource: ProfileResource) => this.resourceToEntity(resource))
      ));
      
    this.clearLocalProfile();
      
    return profile;
  }
  
  private updateProfileInLocalItem(profile: PersistedProfile) {
    
    console.log("Updating profile embedded in local item");
    
    const localItem = this.itemsToSellService.getLocalItem();
    
    if (!localItem) {
      return;
    }
    
    localItem.ownerNickName = profile.username!;
    localItem.location = profile.location;
    this.itemsToSellService.persistLocally(localItem);
    
    console.log("Updated profile embedded in local item");
  }

  public async update(profile: PersistedProfile, newLocationReference?: string): Promise<PersistedProfile> {
    
    const update = {
      username: profile.username,
      location: {
        reference: newLocationReference
      },
      exposeNonListedItems: profile.exposeNonListedItems
    }
    
    return firstValueFrom(this.http.patch<ProfileResource>(`${environment.apiContext}/profiles/${profile.id}`, update)
      .pipe(
        map((resource: ProfileResource) => this.resourceToEntity(resource))
      ));
  }

  public async list(): Promise<PersistedProfile[]> {
    
    return firstValueFrom(this.http.get<ProfileResource[]>(`${environment.apiContext}/profiles`)
      .pipe(
        map((resources: ProfileResource[]) => resources.map(resource => this.resourceToEntity(resource)))
      ));
  }
  
  public async createProfileIfMissing(): Promise<boolean> {
    
    const existingProfiles = await this.list();
    
    if (!existingProfiles || existingProfiles.length === 0) {
      const profile = await this.create();
      this.updateProfileInLocalItem(profile);
      return true;
    }
    
    this.updateProfileInLocalItem(existingProfiles[0]);
    
    return false;
  }
  
  public persistLocally(profile: Profile) {
    localStorage.setItem("local-profile", JSON.stringify({
      username: profile.username,
      location: LocationsService.entityToResource(profile.location),
      exposeNonListedItems: profile.exposeNonListedItems
    }));
  }
  
  public clearLocalProfile() {
    localStorage.removeItem("local-profile");
  }
  
  public getLocalProfile(): Profile | null {
    
    const localProfile = localStorage.getItem("local-profile");
    
    if (!localProfile) {
      return null;
    }
    
    const parsedLocalProfile = JSON.parse(localProfile);
    
    return new Profile(
      LocationsService.resourceToEntity(parsedLocalProfile.location),
      parsedLocalProfile.exposeNonListedItems,
      parsedLocalProfile.username
    );
  }
  
  private resourceToEntity(resource: ProfileResource): PersistedProfile {
    return new PersistedProfile(
          resource.id,
          resource.username,
          resource.identity,
          LocationsService.resourceToEntity(resource.location),
          resource.exposeNonListedItems || undefined
        )
  }
}

  