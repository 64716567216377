import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Globals } from '../globals';
import { tap } from 'rxjs/operators';
import { Angulartics2 } from 'angulartics2';

@Injectable()
export class AlertService {
  
  openSnackbars = 0;
  
  constructor(
      private snacker: MatSnackBar,
      private angulartics: Angulartics2) {
  }

  showSuccess(message: string, opts?: any) {
    
    this.openSnackbars++;
    
    opts = opts || {};
    opts = Object.assign({
      duration: Globals.DEFAULT_TIMEOUT_OF_INFO_DISPLAY,
      verticalPosition: 'top',
      horizontalPosition: 'center'
    }, opts);
    
    this.snacker.open(message, 'Close', opts).afterDismissed().pipe(tap(() => this.openSnackbars--));
    
    this.angulartics.eventTrack.next({
      action: "Show snackbar",
      properties: { 
        category: 'Snackbar',
        label: message,
        type: 'success'
      }
    });
  }

  showError(message: string, opts?: any) {
    
    this.openSnackbars++;
    
    opts = opts || {};
    opts = Object.assign({
      duration: Globals.DEFAULT_TIMEOUT_OF_ERROR_DISPLAY,
      verticalPosition: 'top',
      horizontalPosition: 'center'
    }, opts);
    this.snacker.open(message, 'Close', opts).afterDismissed().pipe(tap(() => this.openSnackbars--));
    
    this.angulartics.eventTrack.next({
      action: "Show snackbar",
      properties: { 
        category: 'Snackbar',
        label: message,
        type: 'error'
      }
    });
  }
}
