import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { LanguageService } from "src/app/_services/language.service";
import { environment } from "src/environments/environment";

@Component({
  selector: 'webview-prompt',
  templateUrl: './webview-prompt.html',
})
export class WebViewPrompt {

  private static BUTTON_LABELS = new Map([
    ['en', new Map([
      ['ios', new Map([['facebook', 'Open in system browser'], ['messenger', 'Open in Safari']])],
      ['android', new Map([['facebook', 'Open in external browser'], ['messenger', 'Open in Chrome']])]
    ])],
    ['et', new Map([
      ['ios', new Map([['facebook', 'Ava süsteemibrauseris'], ['messenger', 'Ava rakenduses Safari']])],
      ['android', new Map([['facebook', 'Ava välises brauseris'], ['messenger', 'Ava rakenduses Chrome']])]
    ])],
    ['ru', new Map([
      ['ios', new Map([['facebook', 'Открыть в браузере системы'], ['messenger', 'Открыть в Safari']])],
      ['android', new Map([['facebook', 'Открыть в стороннем браузере'], ['messenger', 'Открыть в Chrome']])]
    ])]
  ]);
  
  constructor(
    public dialogRef: MatDialogRef<WebViewPrompt>,
    private languageService: LanguageService,
    @Inject(MAT_DIALOG_DATA) public data: { 
      itemToSellId: number,
      isFacebook: boolean,
      isMessenger: boolean,
      isAndroid: boolean,
      isIOS: boolean
    }
  ) {}
  
  get baseURL() {
    return environment.baseURL;
  }
  
  get buttonLabel() {
    
    let language = this.browserLanguage.split('-')[0];
    
    if (!WebViewPrompt.BUTTON_LABELS.has(language)) {
      language = 'en';
    }
    
    return WebViewPrompt.BUTTON_LABELS
        .get(language)
        ?.get(this.data.isAndroid ? 'android' : 'ios')
        ?.get(this.data.isFacebook ? 'facebook' : 'messenger');
  }
  
  get browserLanguage() {
    return this.languageService.getPreferredLanguagesFromBrowser()[0];
  }
}