<div class="example-container" [class.example-is-mobile]="phoneQuery && phoneQuery.matches">
  
  <navigation (onMenuButtonClick)="snav.toggle()"></navigation>
  
  <mat-sidenav-container class="example-sidenav-container" [hasBackdrop]="phoneQuery && phoneQuery.matches">
  
    <mat-sidenav #snav [mode]="phoneQuery && phoneQuery.matches ? 'over' : 'side'"
                 [fixedInViewport]="phoneQuery && phoneQuery.matches" 
                 [opened]="phoneQuery && !phoneQuery.matches"
                 class="sidenav">
      <mat-nav-list>

        <mat-list-item *ngIf="isAuthenticated()">
          <mat-icon matListItemIcon fontSet="material-symbols-outlined">format_list_bulleted</mat-icon>
          <a matListItemTitle 
              (click)="navigate('/items-to-sell')"
              angulartics2On="click" 
              angularticsAction="Link Pressed"
              angularticsCategory="My items"
              angularticsLabel="My items"
              i18n>My items</a>
        </mat-list-item>

        <mat-list-item *ngIf="isAuthenticated()">
          <mat-icon matListItemIcon fontSet="material-symbols-outlined">query_stats</mat-icon>
          <a matListItemTitle 
              (click)="navigate('/dashboard')"
              angulartics2On="click" 
              angularticsAction="Link Pressed"
              angularticsCategory="Statistics"
              angularticsLabel="Statistics"
              i18n>Statistics</a>
        </mat-list-item>
        
        <mat-list-item>
          <mat-icon matListItemIcon fontSet="material-symbols-outlined">help</mat-icon>
          <a matListItemTitle 
              (click)="navigate('/learn-more')"
              angulartics2On="click" 
              angularticsAction="Link Pressed"
              angularticsCategory="Learn how it works"
              angularticsLabel="Learn how it works"
              i18n>Learn how it works</a>
        </mat-list-item>
        
      </mat-nav-list>
      <ng-template [cdkPortalOutlet]="sidenavService.pageSpecificNavigationPortals | async" (attached)="onPageSpecificNavigationPortalsAttached($event)"></ng-template>
    </mat-sidenav>
  
    <mat-sidenav-content class="content">
      <router-outlet></router-outlet>
    </mat-sidenav-content>
    
  </mat-sidenav-container>
  <footer></footer>
</div>