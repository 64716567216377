import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Portal } from '@angular/cdk/portal';
import { Subject } from 'rxjs';

@Injectable()
export class SidenavService {

  pageSpecificNavigationPortals = new Subject<Portal<any> | null>();

  constructor(
    ) {
      
  }
  
  setPageSpecificNavigationPortal(portal: Portal<any> | null) {
    
    if (portal !== null) {
      console.log("Adding sidenav portal")
    } else {
      console.log("Removing sidenav portal")
    }
    
    this.pageSpecificNavigationPortals.next(portal);
  }
}