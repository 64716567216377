import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import mixpanel from 'mixpanel-browser';
import { isPlatformBrowser } from '@angular/common';
import { environment } from 'src/environments/environment';
import { ConsentService, ConsentState } from './consent.service';
import { RUMService } from './rum.service';
import { Angulartics2Mixpanel } from 'angulartics2';
import { Router } from '@angular/router';

@Injectable()
export class AnalyticsService {

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private consentService: ConsentService,
    private rumService: RUMService,
    private mixpanelAngulartics: Angulartics2Mixpanel,
    private router : Router
    ) {
      
    if (isPlatformBrowser(this.platformId)) {
      this.consentService.onConsent(consent => {
        if (consent.state === ConsentState.GRANTED) {
          this.optInForAnalytics();
        }
      });
    }
  }
  
  initAnalytics() {
    
    if (isPlatformBrowser(this.platformId)) {
      
      if (environment.mixpanelEnabled) {
        this.initMixpanel();
      }
      
      this.initGTag();
      this.initRUM();
    }
  }
  
  private optInForAnalytics() {
    console.log("Opting in for analytics");
    this.mixpanelOptIn();
    this.gTagOptIn();
    this.initRUM();
  }
  
  private mixpanelOptIn() {
    mixpanel.opt_in_tracking({ persistence_type: 'localStorage' });
    this.mixpanelAngulartics.pageTrack(this.router.url);
  }
  
  private gTagOptIn() {
    (<any>window).gtag('consent', 'update', {
      'ad_storage': 'granted',
      'ad_user_data': 'granted',
      'ad_personalization': 'granted',
      'analytics_storage': 'granted'
    });
  }
  
  private initMixpanel() {
    
    const optOut = this.consentService.getConsentState() !== ConsentState.GRANTED && environment.consent;
    
    mixpanel.init(environment.mixpanelKey, { 
      debug: false, 
      track_pageview: false, 
      persistence: 'localStorage',
      opt_out_persistence_by_default: optOut,
      opt_out_tracking_by_default: optOut
    });
      
    (<any>window).mixpanel = mixpanel;
    
    this.mixpanelAngulartics.setSuperProperties({browser_languages: navigator.languages})
  }
  
  private initRUM() {
    if (environment.consent && this.consentService.getConsentState() === ConsentState.GRANTED) {
      this.rumService.initRUM();
    }
  }
  
  private initGTag() {
    
    const consentGranted = this.consentService.getConsentState() === ConsentState.GRANTED;
    
    if (consentGranted) {
      this.gTagOptIn();
    }
  }
}