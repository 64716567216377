import { Component, Inject, OnInit, OnDestroy, PLATFORM_ID, Renderer2, ChangeDetectorRef, ViewChild, ComponentRef } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AuthenticationService } from './_services';
import { DOCUMENT, isPlatformBrowser, Location } from '@angular/common';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Angulartics2Mixpanel } from 'angulartics2';
import { Angulartics2GoogleGlobalSiteTag } from 'angulartics2';
import { environment } from 'src/environments/environment';
import { AnalyticsService } from './_services/analytics.service';
import { ConsentSheet } from './components/consent/consent.component';
import { ConsentService } from './_services/consent.service';
import { LanguageService } from './_services/language.service';
import { BreakpointObserver, MediaMatcher } from '@angular/cdk/layout';
import { MaterialContent } from './components/page/material-content.component';
import { MatSidenav } from '@angular/material/sidenav';
import { ComponentPortal, CdkPortalOutletAttachedRef } from '@angular/cdk/portal';
import { SidenavService } from 'src/app/_services/sidenav.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends MaterialContent implements OnInit, OnDestroy {
  
  title = 'Listx';
  isWebView: boolean = false;
  
  phoneQuery: MediaQueryList | undefined;
  private phoneQueryListener: () => void;

  @ViewChild(MatSidenav) sidenav: MatSidenav;
    
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private angularticsMixpanel: Angulartics2Mixpanel,
    private angularticsGTag: Angulartics2GoogleGlobalSiteTag,
    private consentService: ConsentService,
    private bottomSheet: MatBottomSheet,
    private location: Location,
    private analyticsService: AnalyticsService,
    changeDetectorRef: ChangeDetectorRef, 
    breakpointObserver: BreakpointObserver,
    public sidenavService: SidenavService,
    media: MediaMatcher,
    @Inject(PLATFORM_ID) private platformId: any,
    @Inject(DOCUMENT) private document: any,
    private renderer: Renderer2
  ) {
    
    super(breakpointObserver, true)
    
    if (isPlatformBrowser(this.platformId)) {
      this.phoneQuery = media.matchMedia('(max-width: 1007px)');
      this.phoneQueryListener = () => changeDetectorRef.detectChanges();
      this.phoneQuery.addListener(this.phoneQueryListener);
    }
    
    this.router.events.subscribe(event => {
      
      if (event instanceof NavigationEnd) {
        
        this.replaceAlternateLinks();
        
        if (isPlatformBrowser(this.platformId)) {
          this.authenticationService.tryLogIn();
        } else {
          console.log("Skipping auth on server");
        }
      }
    });
  }
  
  ngOnInit(): void {
    
    if (isPlatformBrowser(this.platformId)) {
      this.analyticsService.initAnalytics();
      this.angularticsMixpanel.startTracking();
      this.angularticsGTag.startTracking();  
      this.injectTidio();
    }
    
    if (environment.consent && this.consentService.getConsentState() === null) {
      this.bottomSheet.open(ConsentSheet); 
    }
  }
  
  ngOnDestroy(): void {
    if (this.phoneQuery) {
      this.phoneQuery.removeListener(this.phoneQueryListener);
    }
  }
  
  onPageSpecificNavigationPortalsAttached(ref: CdkPortalOutletAttachedRef | null) {
    if (ref instanceof ComponentRef) {
      ref.instance.onNavigationCompleted.subscribe(() => {
        if (this.phoneQuery && this.phoneQuery.matches) {
          this.sidenav.close();
        }
      });
    }
  }
  
  private injectTidio() {
    let script = this.renderer.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = `//code.tidio.co/${environment.tidio.id}.js`;

    this.renderer.appendChild(this.document.body, script);
  }
  
  private replaceAlternateLinks() {
    this.removeAlternateLinks();
    this.addAlternateLinks();
  }
  
  private removeAlternateLinks() {
    const existingLinks = this.document.querySelectorAll('link[rel="alternate"]');
    existingLinks.forEach((link: any) => {
      this.renderer.removeChild(this.document.head, link);
    });
  }
  
  isAuthenticated(): boolean {
    return this.authenticationService.isAuthenticated();
  }
  
  navigate(path: string) {
    this.router.navigate([path]);
    if (this.phoneQuery && this.phoneQuery.matches) {
      this.sidenav.close();
    }
  }
  
  private addAlternateLinks() {
    console.log(this.location.path())
    LanguageService.SUPPORTED_LOCALES.forEach(locale => {
      
      const localeSpecificURL = `${environment.baseURL}/${locale}${this.location.path()}`;
      this.addAlternateLink(locale, localeSpecificURL);
      const localeParts = locale.split("-");
      if (localeParts.length == 2) {
        this.addAlternateLink(localeParts[0], localeSpecificURL);
      }
    })
    
    this.addAlternateLink('x-default', `${environment.baseURL}${this.location.path()}`);
  }
  
  private addAlternateLink(language: string, url: string) {
    const link = this.renderer.createElement('link');
    this.renderer.setAttribute(link, 'rel', 'alternate');
    this.renderer.setAttribute(link, 'hreflang', language);
    this.renderer.setAttribute(link, 'href', url);
    this.renderer.appendChild(this.document.head, link);
  }
}
