import { ActivatedRoute, Router } from '@angular/router';
import { Component, EventEmitter, Output, ViewChild, ComponentRef } from '@angular/core';
import { AuthenticationService, GMailService, UserService } from '../../_services';
import { OAuthService } from 'angular-oauth2-oidc';
import { Globals, Role } from '../../globals';
import { ChatService } from 'src/app/_services/chat.service';
import { ComponentPortal, CdkPortalOutlet, CdkPortalOutletAttachedRef } from '@angular/cdk/portal';
import { SearchInputComponent } from 'src/app/modules/search/components/search-input/search-input.component';
import { WebViewService } from 'src/app/_services/webview.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent {

  integrationIssuedAt?: string | null;
  numberOfUnreadMessages: number;
  polling = false;
  searchPortal: ComponentPortal<SearchInputComponent> = new ComponentPortal(SearchInputComponent);
  @ViewChild(CdkPortalOutlet, {static: false}) searchPortalOutlet: CdkPortalOutlet;
  searchByCategoryEnabled = environment.searchByCategory;
  
  @Output() onMenuButtonClick: EventEmitter<void> = new EventEmitter();

  constructor(private router: Router,
              public route: ActivatedRoute,
              private userService: UserService,
              private authenticationService: AuthenticationService,
              private chatService: ChatService,
              private webViewService: WebViewService,
              private gmailService: GMailService,
              private oauthService: OAuthService) {
    
    this.router.events.subscribe(() => {
      this.init();
    });
  }
  
  async init() {
    
    if (!this.LOGGED) {
      return;
    }
    
    const [unreadMessageCount, gmailIntegration] = await Promise.all([
      this.chatService.getNumberOfUnreadMessages(),
      this.gmailService.getIntegration()
    ]);
    
    this.numberOfUnreadMessages = unreadMessageCount;
    this.integrationIssuedAt = gmailIntegration;
    
    this.schedulePoll();
  }
  
  onSearchPortalAttached(ref: CdkPortalOutletAttachedRef | null) {
    if (ref instanceof ComponentRef) {
      ref.instance.onFiltersClicked.subscribe(() => {
        this.menuClicked();
      });
    }
  }
  
  private async schedulePoll(): Promise<void> {
    
    if (this.polling) {
      console.log("Already polling");
      return;
    }
    
    setTimeout(() => { this.poll() }, 10000);
    this.polling = true;
  }
  
  async poll(): Promise<void> {
    
    if (!this.LOGGED) {
      console.log("User not logged in, no point to poll");
      return;
    }
    
    console.log("Polling messages");
    await this.loadNumberOfUnreadMessages();
    
    console.log("Scheduling another poll");
    setTimeout(() => { this.poll() }, 10000);      
  }
  
  private async loadNumberOfUnreadMessages() {
    this.numberOfUnreadMessages = await this.chatService.getNumberOfUnreadMessages();
  }
  
  get IS_ADMIN(): boolean {
    const user = this.userService.getLoggedUser();
    
    if (!user) {
      return false;
    }
    
    return user.hasRole(Globals.ROLE_PREFIX + Role.ADMIN) || user.hasRole(Role.ADMIN);
  }
  
  get LOGGED_USER() {
    return this.userService.getLoggedUser();
  }
  
  get LOGGED(): boolean {
    return this.userService.isLoggedIn();
  }
  
  login() {
    if (!this.webViewService.detectWebView()) {
      this.authenticationService.login("/");
    }
  }

  logout() {
    this.authenticationService.logout();
  }
  
  toChats() {
    this.router.navigate(["/chats"]);
  }
  
  toSelectListingFlow() {
    this.router.navigate(["/select-listing-flow"]);
  }
  
  async menuClicked() {
    this.onMenuButtonClick.emit();
  }
}
