<h2 mat-dialog-title i18n>For the Best Experience, Use Your Phone's Browser</h2>
<mat-dialog-content>
  <p i18n>
    For enhanced security and optimal functionality, please open 
    <a [href]="baseURL + (data.itemToSellId ? '/view-listing/' + data.itemToSellId : '')">
      {{baseURL + (data.itemToSellId ? '/view-listing/' + data.itemToSellId : '')}}
    </a> 
    in your phone's browser (Chrome, Safari, Firefox, etc.).
  </p>
  
  <p *ngIf="data.isAndroid && data.isFacebook">
    <span i18n>In Facebook, press <mat-icon fontSet="material-symbols-outlined">more_horiz</mat-icon> menu button and then</span> <b> {{buttonLabel}}</b>.
  </p>
  
  <p *ngIf="data.isIOS && data.isFacebook">
    <span i18n>In Facebook, press <mat-icon fontSet="material-symbols-outlined">more_horiz</mat-icon> menu button and then</span> <b> {{buttonLabel}}</b>.
  </p>
  
  <p *ngIf="data.isAndroid && data.isMessenger">
    <span i18n>In Messenger, press <mat-icon fontSet="material-symbols-outlined">more_vert</mat-icon> menu button and then</span> <b> {{buttonLabel}}</b>.
  </p>
  
  <p *ngIf="data.isIOS && data.isMessenger">
    <span i18n>In Messenger, press <mat-icon fontSet="material-symbols-outlined">ios_share</mat-icon> menu button and then</span> <b> {{buttonLabel}}</b>.
  </p>
  
</mat-dialog-content>
<mat-dialog-actions class="button-panel">
  <button mat-button mat-dialog-close i18n>CANCEL</button>
</mat-dialog-actions>