import { Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { firstValueFrom, map } from 'rxjs';
import { environment } from '../../environments/environment';
import { DefaultAPIErrorHandler } from '../_interceptors';

export interface CreatePictureUploadURLResource {
}

export interface PictureUploadURLResource {
  url: string;
  key: string;
}

export interface PictureRecommendationResource {
  title: string;
  description: string;
}

export interface PictureRecommendationsResource {
  itemType: string;
  recommendations: PictureRecommendationResource[];
}

export class PictureRecommendation {
  
  constructor(readonly title: string, readonly description: string) {
  }
}

export class PictureRecommendations {
  
  constructor(readonly itemType: string, readonly recommendations: PictureRecommendation[]) {
  }
}


export class PictureUploadURL {
  constructor(readonly url: string, readonly key: string) {}
}

@Injectable()
export class PictureService {
  
  constructor(
      protected http: HttpClient) {
  }
  
  public async upload(body: File): Promise<PictureUploadURL> {
    
    const urlResponse = await firstValueFrom(this.http.post<PictureUploadURLResource>(`${environment.apiContext}/upload-urls`, { contentType: body.type }));
    
    console.log("Uploading image to " + urlResponse.url);
    
    const headers = { 'Cache-Control': 'public, max-age=31536000, immutable' };
    
    await firstValueFrom(this.http.put<void>(urlResponse.url, body, { headers }));
    
    return new PictureUploadURL(urlResponse.url, urlResponse.key);
  }
  
  public async createThumbnail(originalKey: string): Promise<void> {
    
    await firstValueFrom(this.http.post<void>(`${environment.apiContext}/thumbnails`, { originalKey: originalKey }));
  }
  
  public async getPictureRecommendations(pictureKey: string): Promise<PictureRecommendations | undefined> {
    
    let queryParams = new HttpParams();
    queryParams = queryParams.set("key", pictureKey);
    
    const context = new HttpContext().set(DefaultAPIErrorHandler.HANDLED_STATUS_CODES, [404]);
    
    try {
      return firstValueFrom(this.http.get<PictureRecommendationsResource>(`${environment.apiContext}/picture-recommendations`, { params: queryParams, context: context })
        .pipe(map(resource => new PictureRecommendations(
          resource.itemType,
          resource.recommendations.map(recommendation => new PictureRecommendation(recommendation.title, recommendation.description)))))
      );
    } catch (error) {
      if (error instanceof HttpErrorResponse && error.status === 404) {
        return undefined;
      }
      
      throw error;
    }
  }
  
  public async hasThumbnail(thumbnailKey: string): Promise<boolean> {
    
    const context = new HttpContext().set(DefaultAPIErrorHandler.HANDLED_STATUS_CODES, [403]);
    
    try {
      await firstValueFrom(this.http.head<void>(`${environment.picturesUrl}/${thumbnailKey}`, { context: context }));  
    } catch (error) {
      
      if (error instanceof HttpErrorResponse && error.status === 403) {
        return false;
      }
      
      throw error;
    }
    
    return true;
  }
  
  /**@deprecated */
  public async calculatePictureStats(): Promise<any> {
    
    await firstValueFrom(this.http.get<any>(`${environment.apiContext}/picture-stats`, {}));
  }
}

	