
import { Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable, throwError, of, firstValueFrom } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { DefaultAPIErrorHandler } from '../_interceptors';

export interface AuthorizationURIResource {
  uri: string;
}

export interface CreateGMailIntegrationResource {
  state: string;
  code: string;
  callbackUri: string;
}

export interface GMailIntegrationResource {
  issuedAt: string;
}

export interface CreateAuthorizationURIResource {
  provider: string;
  callbackUri: string;
}

export enum ScanGMailRejectionReason {
  NO_AUTHORIZATION = "not_authorised"
}

export class ScanGMailResponse {
}

export class SuccessfulStartIntegrationResponse extends ScanGMailResponse {
}

export class RejectedStartIntegrationResponse extends ScanGMailResponse {
  constructor(readonly reason: ScanGMailRejectionReason) {
    super();
  }
}

enum GmailScanState {
  IN_PROGRESS = "IN_PROGRESS", 
  DONE = "DONE"
}

interface GmailScanResource {
  state: GmailScanState
}

class GmailScan {
  constructor(state: GmailScanState) {}
}

@Injectable()
export class GMailService {

  constructor(
      protected http: HttpClient) {
  }

  public startIntegration(callbackURI: string): Observable<string> {
    
    const requestBody: CreateAuthorizationURIResource = {
      provider: "gmail-client",
      callbackUri: callbackURI
    }
    
    return this.http.post<AuthorizationURIResource>(`${environment.apiContext}/integration-authorization-uris`, requestBody)
      .pipe(
        map((resource: AuthorizationURIResource) => resource.uri)
      );
  }
  
  public createIntegration(code: string, state: string, originalCallbackURI: string): Observable<string> {
    
    const requestBody: CreateGMailIntegrationResource = {
      state: state,
      code: code,
      callbackUri: originalCallbackURI
    }
    
    return this.http.post<GMailIntegrationResource>(`${environment.apiContext}/integrations/gmail`, requestBody)
      .pipe(map(resource => resource.issuedAt));
  }
  
  async getIntegration(): Promise<string | null> {
    
    const context = new HttpContext().set(DefaultAPIErrorHandler.HANDLED_STATUS_CODES, [404]);
    
    return firstValueFrom(this.http.get<GMailIntegrationResource>(`${environment.apiContext}/integrations/gmail`, { context: context })
      .pipe(
        map(resource => resource.issuedAt),
        catchError((error: HttpErrorResponse) => {
          if (error.status === 404) {
            console.log("No integration found");
            return of(null);
          }
          return throwError(error);
        })
      ));
  }
  
  async listOngoingScans(): Promise<GmailScan[]> {
    
    let queryParams = new HttpParams();
    
    queryParams = queryParams.set("state", GmailScanState.IN_PROGRESS);
    
    return firstValueFrom(
      this.http.get<GmailScanResource[]>(`${environment.apiContext}/integrations/gmail/scans`, { params: queryParams })
        .pipe(
          map(resources => resources.map(resource => {
            return new GmailScan(resource.state);
          }))
        )
    );
  }  
  
  public scanGMail(): Observable<SuccessfulStartIntegrationResponse | RejectedStartIntegrationResponse> {
    return this.http.post<void>(`${environment.apiContext}/integrations/gmail/scans`, { async: true })
      .pipe(
        map(() => {
          return new SuccessfulStartIntegrationResponse();
        }),
        catchError((error: HttpErrorResponse) => {
          if (error.status === 400) {
            if (error.error[0].code === "not_authorised") {
              return of(new RejectedStartIntegrationResponse(ScanGMailRejectionReason.NO_AUTHORIZATION));
            }
          }
          return throwError(error);
        })
      );
  }
}

	