import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";


export class MaterialContent {
  
  gridColumns: number = 12;
  bodyWidth: number | undefined = undefined;
  bodyScaling = false;
  leftMarginWidth: number | undefined;
  rightMarginWidth: number | undefined;
  rightPaddingWidth: number | undefined;
  marginWidth = 200;
  isPhone: boolean;
  isTablet: boolean;
  isDesktop: boolean;
  
  constructor (breakpointObserver: BreakpointObserver, materialV2: boolean = false) {
    
    this.materialV2Breakpoints(breakpointObserver);
  }
  
  private materialV2Breakpoints(breakpointObserver: BreakpointObserver) {
    
    breakpointObserver.observe([
      '(max-width: 599px)'
    ]).subscribe(result => {
      if (result.matches) {
        this.gridColumns = 4;
        this.leftMarginWidth = 16;
        this.rightMarginWidth = 16;
        this.rightPaddingWidth = undefined;
        this.bodyWidth = undefined;
        
        this.isPhone = true;
        this.isTablet = false;
        this.isDesktop = false;
      }
    });

    breakpointObserver.observe([
      '(min-width: 600px) and (max-width: 904px)'
    ]).subscribe(result => {
      if (result.matches) {
        this.gridColumns = 8;
        this.leftMarginWidth = 32;
        this.rightMarginWidth = 32;
        this.rightPaddingWidth = undefined;
        this.bodyWidth = undefined;
        this.isPhone = false;
        this.isTablet = true;
        this.isDesktop = false;
      }
    });

    //sidenav doesn't fit    
    breakpointObserver.observe([
      '(min-width: 905px) and (max-width: 1007px)'
    ]).subscribe(result => {
      if (result.matches) {
        this.gridColumns = 12;
        this.bodyWidth = 840;
        this.leftMarginWidth = undefined;
        this.rightMarginWidth = undefined;
        this.rightPaddingWidth = undefined;
        this.isPhone = false;
        this.isTablet = true;
        this.isDesktop = false;
      }
    });
    
    //sidenav with labels doesn't fit
    breakpointObserver.observe([
      '(min-width: 1008px) and (max-width: 1191px)'
    ]).subscribe(result => {
      if (result.matches) {
        this.gridColumns = 12;
        this.bodyWidth = 840;
        this.leftMarginWidth = 0;
        this.rightMarginWidth = undefined;
        this.rightPaddingWidth = undefined;
        this.isPhone = false;
        this.isTablet = true;
        this.isDesktop = false;
      }
    });
    
    breakpointObserver.observe([
      '(min-width: 1192px) and (max-width: 1239px)'
    ]).subscribe(result => {
      if (result.matches) {
        this.gridColumns = 12;
        this.bodyWidth = 840;
        this.leftMarginWidth = 0;
        this.rightMarginWidth = undefined;
        this.rightPaddingWidth = undefined;
        this.isPhone = false;
        this.isTablet = true;
        this.isDesktop = false;
      }
    });
    
    breakpointObserver.observe([
      '(min-width: 1240px) and (max-width: 1439px)'
    ]).subscribe(result => {
      if (result.matches) {
        this.gridColumns = 12;
        this.leftMarginWidth = 0;
        this.rightMarginWidth = 200;
        this.rightPaddingWidth = undefined;
        this.bodyWidth = undefined;
        this.isPhone = false;
        this.isTablet = false;
        this.isDesktop = true;
      }
    });
    
    breakpointObserver.observe([
      '(min-width: 1440px) and (max-width: 1647px)'
    ]).subscribe(result => {
      if (result.matches) {
        this.gridColumns = 12;
        this.bodyWidth = 1040;
        this.leftMarginWidth = 0;
        this.rightMarginWidth = undefined;
        this.rightPaddingWidth = undefined;
        this.isPhone = false;
        this.isTablet = false;
        this.isDesktop = true;
      }
    });
    
    breakpointObserver.observe([
      '(min-width: 1648px)'
    ]).subscribe(result => {
      if (result.matches) {
        this.gridColumns = 12;
        this.bodyWidth = 1040;
        this.leftMarginWidth = undefined;
        this.rightMarginWidth = undefined;
        this.rightPaddingWidth = 256;
        
        this.isPhone = false;
        this.isTablet = false;
        this.isDesktop = true;
      }
    });
  }
  
  get gridStyles() {
    
    const styles: any = {
      //'padding-left.px': 48
    };
    
    if (this.rightPaddingWidth !== undefined) {
      styles["padding-right.px"] = this.rightPaddingWidth;
    }
    
    if (this.bodyWidth !== undefined) {
      styles["width.px"] = this.bodyWidth;
    }
    
    if (this.leftMarginWidth !== undefined) {
      styles["margin-left.px"] = this.leftMarginWidth;
    } else {
      styles["margin-left"] = 'auto';
    }
    
    if (this.rightMarginWidth !== undefined) {
      styles["margin-right.px"] = this.rightMarginWidth;
    } else {
      styles["margin-right"] = 'auto';
    }
    
    return styles;
  }
}