import { Component, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss']
})
export class SearchInputComponent {

  searchForm = new FormGroup({
    q: new FormControl('', [Validators.maxLength(1000)]),
  });
  
  currentCategory: string | undefined;
  @Output() onFiltersClicked: EventEmitter<void> = new EventEmitter();
  numberOfAppliedFilters: number = 0;

  constructor(
    private router: Router,
    activatedRoute: ActivatedRoute) {
    
    activatedRoute.queryParams.subscribe(queryParams => {
      
      this.currentCategory = queryParams["categoryCode"];
      
      if (queryParams["categoryCode"]) {
        this.numberOfAppliedFilters = 1;
      } else {
        this.numberOfAppliedFilters = 0;
      }
      
      if (queryParams["q"]) {
        this.searchForm.controls["q"].setValue(queryParams["q"]); 
      }
    })
  }
  
  get showFilterButton(): boolean {
    return this.numberOfAppliedFilters > 0;
  }
  
  search() {
    
    if (this.searchForm.invalid) {
      console.log("Invalid");
      return;
    }
    
    const params: any = {
      q: this.searchForm.controls["q"].value
    }
    
    if (this.currentCategory) {
      params["categoryCode"] = this.currentCategory;
    }
    
    this.router.navigate(["/"], { queryParams: params });
  }
  
  triggerOnFiltersClicked() {
    this.onFiltersClicked.emit();    
  }
}
