<mat-toolbar style="position: fixed; z-index: 1000;">

  <span class="global-menu">
    <button mat-icon-button (click)="menuClicked()">
      <mat-icon fontSet="material-symbols-outlined">menu</mat-icon>
    </button>
  </span>
  
  <a class="logo" routerLink="/">
    <img class="full-logo" [src]="'/logo/logo_full_2.webp' | assetUrl"/>
  </a>

  <span class="search-input">
    <ng-template [cdkPortalOutlet]="searchPortal" (attached)="onSearchPortalAttached($event)"></ng-template>
  </span>
  
  <span class="spacer"></span>
  
  <span *ngIf="LOGGED" class="button-section">
    
    <button (click)="toSelectListingFlow()" 
      mat-flat-button 
      angulartics2On="click" 
      angularticsAction="Button Pressed"
      angularticsCategory="Sell"
      angularticsLabel="SELL"
      color="accent"
      i18n>
      SELL
    </button>
    
    <button (click)="toChats()" 
        mat-icon-button angulartics2On="click"
        angulartics2On="click"  
        angularticsAction="Button Pressed"
        angularticsCategory="Conversations"
    >
        
      <mat-icon fontSet="material-symbols-outlined" 
          [matBadge]="numberOfUnreadMessages" 
          [matBadgeHidden]="numberOfUnreadMessages === 0" 
          matBadgeColor="accent">mail</mat-icon>
    </button>
    
    <mat-menu #accountMenu="matMenu">
      <a routerLink="account" 
          mat-menu-item
          angulartics2On="click" 
          angularticsAction="Link Pressed"
          angularticsCategory="Account & settings"
          angularticsLabel="Account & settings">
        <mat-icon fontSet="material-symbols-outlined">settings</mat-icon>
        <span i18n>Account & settings</span>
      </a>
      <button (click)="logout();" 
          mat-menu-item
          angulartics2On="click" 
          angularticsAction="Link Pressed"
          angularticsCategory="Log out"
          angularticsLabel="Log out">
        <mat-icon fontSet="material-symbols-outlined">logout</mat-icon>
        <span i18n>Log out</span>
      </button>
    </mat-menu>
    <button mat-icon-button [matMenuTriggerFor]="accountMenu" class="account-menu">
      <mat-icon fontSet="material-symbols-outlined">account_circle</mat-icon>
    </button>
  </span>
  
  <span *ngIf="!LOGGED" class="button-section">
  
    <button (click)="toSelectListingFlow()" 
      mat-flat-button 
      angulartics2On="click" 
      angularticsAction="Button Pressed"
      angularticsCategory="Sell"
      angularticsLabel="SELL"
      color="accent"
      i18n>
      SELL
    </button>
  
    <button 
        mat-stroked-button
        angulartics2On="click" 
        angularticsAction="Button Pressed"
        angularticsCategory="Sign in"
        angularticsLabel="SIGN IN"
        (click)="login();"
        i18n>
      LOG IN
    </button>
  </span>
  
  <language-selector *ngIf="!LOGGED"/>
</mat-toolbar>
