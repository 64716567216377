import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { AwsRum } from 'aws-rum-web';

@Injectable()
export class RUMService {

  private awsRum: AwsRum | undefined;

  constructor() {
        
  }
  
  initRUM() {
    try {
      this.awsRum = new AwsRum(
        environment.awsRUM.applicationId,
        environment.awsRUM.applicationVersion,
        environment.awsRUM.applicationRegion,
        environment.awsRUM.config
      );
    } catch (e) {
      console.error("Failed to init AWS RUM ", e);
    }
  }
  
  handleError(error: any) {
    this.awsRum?.recordError(error);
  }
}

  