import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, firstValueFrom } from 'rxjs';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { getDay, parseJSON } from 'date-fns';
import { Money } from '@dintero/money';

export class Purchase {
  
  constructor(
    readonly reference: string,
    readonly shopName: string,
    readonly dateTimeOfPurchase: Date,
    readonly price: Money,
    readonly priceEUR: Money,
    readonly shippingCost: Money,
    readonly shippingCostEUR: Money,
    readonly discountEUR: Money) {
  }
  
  get weekdayOfPurchase(): number {
    return (getDay(this.dateTimeOfPurchase) + 6) % 7;
  }
}

export interface PurchaseResource {
  dateTimeOfPurchase: string;
  reference: string;
  shopName: string;
  price: {
    amount: number;
    currency: string;
  };
  priceEUR: {
    amount: number;
    currency: string;
  };
  shippingCost: {
    amount: number;
    currency: string;
  };
  shippingCostEUR: {
    amount: number;
    currency: string;
  };
  discountEUR: {
    amount: number;
    currency: string;
  };
}

@Injectable()
export class PurchaseService {

  constructor(
      protected http: HttpClient) {
  }

  public async list(): Promise<Purchase[]> {
    
    return firstValueFrom(this.http.get<PurchaseResource[]>(`${environment.apiContext}/purchases`)
      .pipe(
        map((resources: PurchaseResource[]) => resources.map(resource => new Purchase(
          resource.reference,
          resource.shopName,
          parseJSON(resource.dateTimeOfPurchase),
          Money.of(resource.price.amount, resource.price.currency),
          Money.of(resource.priceEUR.amount, resource.priceEUR.currency),
          Money.of(resource.shippingCost.amount, resource.shippingCost.currency),
          Money.of(resource.shippingCostEUR.amount, resource.shippingCostEUR.currency),
          Money.of(resource.discountEUR.amount, resource.discountEUR.currency)
        )))
      ));
  }
}

  