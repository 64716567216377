import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import {UserService} from '../_services/user.service';

@Injectable()
export class AuthGuard  {

  constructor(protected router: Router, protected userService: UserService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    if (this.userService.isLoggedIn()) {
      return true;
    }

    this.redirectToLoginPage(state);
    
    return false;
  }

  protected redirectToLoginPage(state: RouterStateSnapshot) {
    this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
  }
}