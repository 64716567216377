import {Role} from '../globals';
	
export class User {
	
	public id?: string;
  public username?:   string;
  public password?:   string;
  public firstName?:  string;
  public lastName?:   string;
  public name?: string;
  public email?:      string;
  public confirmEmail?:   string;
  public email2?:      string;
  public captcha?:    string;
  public token?:      string;
  public roles: string[]    = [];
  public authorities: string[]  = [];
  public phone1?:     string;
  public phone2?:     string;
  public permissions?: any;
  public registered?: boolean;
  public active = true;
  public role?: any;
  public isMobile: any;

  hasRole (name: string): boolean {
    if (!this.roles) {
      return false;
    }
    
    return this.hasRoleInRoles(name) || this.hasRoleInAuthorities(name);
  }
  
  private hasRoleInRoles(role: string): boolean {
  	if (!role) {
  	  return false;
  	}
	  
    return this.roles.some(entry => (entry === role));
  }
  
  private hasRoleInAuthorities(role: string): boolean {
    return this.authorities.some(entry => (entry === role));
  }
}
