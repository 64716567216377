import {Injectable} from '@angular/core';

@Injectable()
export class Globals {

  public static ROLE_PREFIX = 'ROLE_';
  public static DEFAULT_TIMEOUT_OF_INFO_DISPLAY = 3000;
  public static DEFAULT_TIMEOUT_OF_ERROR_DISPLAY = 15000;
}

export enum Role {
  ADMIN = 'admin'
}