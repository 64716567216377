import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';

import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { Angulartics2 } from 'angulartics2';
import { WebViewPrompt } from '../modules/shared/webview-prompt/components/webview-prompt/webview-prompt.component';

@Injectable()
export class WebViewService {

  constructor(
    private dialog: MatDialog,
    private angulartics: Angulartics2
    ) {
  }
  
  public detectWebView(itemToSellId?: number): boolean {
    
    if (environment.blockWebView && this.isWebView()) {
      this.openDialog(itemToSellId);
      
      return true;
    }
    
    return false;
  }
  
  private openDialog(itemToSellId?: number) {
    
    const data = { 
      itemToSellId: itemToSellId,
      isFacebook: this.isFacebookWebView(),
      isMessenger: this.isMessengerWebView(),
      isAndroid: this.isAndroidDevice(),
      isIOS: this.isIOSDevice()
    }
    
    this.dialog.open(WebViewPrompt, {
      data: data
    });
    
    this.angulartics.eventTrack.next({
      action: "Open dialog",
      properties: { 
        category: 'Dialog',
        label: "Web view block",
        data: data
      }
    });
  }
  
  /**
   * Example from Facebook in-app browser: Mozilla/5.0 (iPhone; CPU iPhone OS 17_0_3 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/21A360 [FBAN/FBIOS;FBDV/iPhone12,3;FBMD/iPhone;FBSN/iOS;FBSV/17.0.3;FBSS/3;FBID/phone;FBLC/en_GB;FBOP/5]
   * Messenger: Mozilla/5.0 (iPhone; CPU iPhone OS 16_3_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/15E148 LightSpeed [FBAN/MessengerLiteForiOS;FBAV/433.0.0.31.116;FBBV/532473177;FBDV/iPhone13,1;FBMD/iPhone;FBSN/iOS;FBSV/16.3.1;FBSS/3;FBCR/;FBID/phone;FBLC/en-GB;FBOP/0]
   */
  private isWebView(): boolean {
    
    // Android WebView detection
    const isAndroidWebView = /; wv\)/.test(navigator.userAgent);
  
    // iOS WebView detection (based on patterns seen in WKWebViews)
    const isiOSWebView = /.app\)/.test(navigator.userAgent);
  
    // Detection for Facebook's in-app browser (both iOS and Android)
    const isFacebookInAppBrowser = this.isFacebookWebView();
  
    // Instagram's in-app browser
    const isInstagramInAppBrowser = /Instagram/.test(navigator.userAgent);
  
    // Twitter's in-app browser
    const isTwitterInAppBrowser = /Twitter/.test(navigator.userAgent);
  
    // Snapchat's in-app browser
    const isSnapchatInAppBrowser = /Snapchat/.test(navigator.userAgent);
    
    // Standalone mode means the page was added to the home screen (iOS behavior)
    const isiOSStandalone = ('standalone' in window.navigator) && (window.navigator['standalone'] as boolean);
  
    return isAndroidWebView 
        || isiOSWebView 
        || isFacebookInAppBrowser 
        || isInstagramInAppBrowser 
        || isTwitterInAppBrowser 
        || isSnapchatInAppBrowser 
        || isiOSStandalone
        || this.isMessengerWebView();
  }
  
  private isFacebookWebView(): boolean {
    
    if (!window as any ["FbQuoteShareJSInterface"]) {
      return false;
    }
    
    return  /\[FBAN\//.test(navigator.userAgent) || /\[FB_IAB\/FB4A;/.test(navigator.userAgent);
  }
  
  private isMessengerWebView(): boolean {
    
    if (this.isFacebookWebView()) {
      return false;
    }
    
    if (/FBAN\/Messenger/i.test(navigator.userAgent) || /FBAV/i.test(navigator.userAgent) && /Android/i.test(navigator.userAgent)) {
      return true;
    }
    
    return /FBAN\/MessengerLiteForiOS/i.test(navigator.userAgent) || /FBAV/i.test(navigator.userAgent) && /iPhone|iPad|iPod/i.test(navigator.userAgent);
  }
    
  private isAndroidDevice(): boolean {
    // Check if the user agent contains 'Android'
    return /Android/i.test(navigator.userAgent);
  }
  
  private isIOSDevice(): boolean {
    // Check for iOS device identifiers (iPhone, iPad, iPod)
    return /iPhone|iPad|iPod/i.test(navigator.userAgent);
  }
}