import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { Angulartics2Module } from 'angulartics2';
import { WebViewPrompt } from './components/webview-prompt/webview-prompt.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
    imports: [
        CommonModule,
        MatButtonModule,
        MatDialogModule,
        MatIconModule,
        Angulartics2Module,
     ],
    declarations: [
        WebViewPrompt,
    ],
    exports: [
        WebViewPrompt,
    ]
})
export class WebViewPromptModule {}