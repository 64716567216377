import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { ItemToSell, ItemToSellResource, ItemsToSellService } from './items-to-sell.service';
import { AuthenticationService } from 'src/app/_services';
import { parseISO } from 'date-fns';

export class Chat {
  
  constructor(
    readonly id: number,
    readonly authorUsername: string,
    readonly itemToSell: ItemToSell,
    readonly totalMessages: number,
    readonly unreadMessages: number,
    readonly createdTime: Date,
    readonly lastMessage: ChatMessage | null,
    readonly token: string
  ) {}
}

export interface ChatResource {

  id: number;  
  authorUsername: string;
  itemToSell: ItemToSellResource;
  totalMessages: number;
  unreadMessages: number;
  createdTime: string;
  lastMessage: ChatMessageResource | null;
  token: string;
}

export class ChatMessage {
  
  constructor(
      readonly id: number,
      readonly message: string,
      public unread: boolean,
      readonly createdTime: Date,
      readonly fromSeller: boolean,
      readonly authorUsername?: string,
  ) {}
}

export interface ChatMessageResource {
  
  id: number;
  message: string;
  unread: boolean;
  createdTime: string;
  authorUsername: string | null;
  fromSeller: boolean;
}

export interface UnreadMessageCountResource {
  
  unreadMessageCount: number;
}

@Injectable()
export class ChatService {

  constructor(
      protected http: HttpClient,
      private authenticationService: AuthenticationService) {
  }

  public async listChats(itemToSellId?: number): Promise<Chat[]> {
    
    return firstValueFrom(this.http.get<ChatResource[]>(`${environment.apiContext}/chats${itemToSellId ? '?itemToSellId=' + itemToSellId : ''}`)
      .pipe(
        map((resources: ChatResource[]) => resources.map(resource => this.resourceToEntity(resource)))
      ));
  }
  
  public async getChatMessages(chatId: number, limit: number = 100): Promise<ChatMessage[]> {
    
    return firstValueFrom(this.http.get<ChatMessageResource[]>(`${environment.apiContext}/chats/${chatId}/messages?maxMessages=${limit}`)
      .pipe(
        map((resources: ChatMessageResource[]) => resources.map(resource => this.chatMessageResourceToEntity(resource)))
      ));
  }
  
  public async sendMessage(chatId: number, chatMessage: string): Promise<ChatMessage> {
    
    const createRequest = {
      message: chatMessage,
      token: this.authenticationService.isAuthenticated() ? null : this.getStoredChatTokens()[chatId]
    }
    
    return firstValueFrom(this.http.post<ChatMessageResource>(`${environment.apiContext}/chats/${chatId}/messages`, createRequest)
      .pipe(
        map((resource: ChatMessageResource) => this.chatMessageResourceToEntity(resource))
      ));
  }
  
  public async startChat(itemToSellId: number, emailForReplies?: string): Promise<Chat> {
    
    const createRequest = {
      itemToSellId: itemToSellId,
      authorEmail: emailForReplies      
    }
    
    const chat = await firstValueFrom(this.http.post<ChatResource>(`${environment.apiContext}/chats`, createRequest)
      .pipe(
        map((resource: ChatResource) => this.resourceToEntity(resource))
      ));
      
    this.storeChatToken(chat.id, chat.token);
      
    return chat;
  }
  
  private storeChatToken(chatId: number, token: string) {
    const existingTokens = this.getStoredChatTokens();
    existingTokens[chatId] = token;
    sessionStorage.setItem('listx_chat_tokens', JSON.stringify(existingTokens));
  }
  
  private getStoredChatTokens(): any {
    
    const persistedString = sessionStorage.getItem('listx_chat_tokens');
    
    if (!persistedString) {
      return {};
    }
    
    return JSON.parse(persistedString);
  }
  
  public async markAsRead(chatId: number, messageId: number): Promise<ChatMessage> {
    
    const updateRequest = {
      unread: false
    }
    
    return firstValueFrom(this.http.patch<ChatMessageResource>(`${environment.apiContext}/chats/${chatId}/messages/${messageId}`, updateRequest)
      .pipe(
        map((resource: ChatMessageResource) => this.chatMessageResourceToEntity(resource))
      ));
  }
  
  public async getNumberOfUnreadMessages(): Promise<number> {
    
    return firstValueFrom(this.http.get<UnreadMessageCountResource[]>(`${environment.apiContext}/unread-message-counts`)
      .pipe(
        map((resources: UnreadMessageCountResource[]) => resources[0].unreadMessageCount)
      ));
  }
  
  
  public chatMessageResourceToEntity(resource: ChatMessageResource): ChatMessage {
    return new ChatMessage(
            resource.id,
            resource.message,
            resource.unread,
            parseISO(resource.createdTime),
            resource.fromSeller,
            resource.authorUsername || undefined);
  }
  
  public resourceToEntity(resource: ChatResource): Chat {
    return new Chat(
          resource.id,
          resource.authorUsername,
          ItemsToSellService.resourceToEntity(resource.itemToSell),
          resource.totalMessages,
          resource.unreadMessages,
          parseISO(resource.createdTime),
          resource.lastMessage ? this.chatMessageResourceToEntity(resource.lastMessage) : null,
          resource.token);
  }
}

  