export const environment = {
  production: true,
  online: true,
  apiContext: '/api',
  oidcIssuer: 'https://cognito-idp.eu-west-1.amazonaws.com/eu-west-1_gTvi62rd6',
  oidcClientId: '5a2m8slnddptj21uqs692krhdf',
  oidcLogoutUrl: 'https://login.listx.ai/logout',
  oidcScopes: 'openid aws.cognito.signin.user.admin',
  picturesUrl: '',
  baseURL: 'https://www.listx.ai',
  loginCallbackBaseURL: 'https://www.listx.ai',
  assetsBaseURL: '/assets',
  mixpanelEnabled: true,
  mixpanelKey: 'fc6d082756ff945b667fe13c0001b9f5',
  blockWebView: true,
  nonListedItems: true,
  awsRUM: {
    applicationId: 'ab8f3f5c-c8ea-471c-b5ca-b07338d3e071',
    applicationVersion: '1.0.0',
    applicationRegion: 'eu-west-1',
    config: {
      sessionSampleRate: 1,
      guestRoleArn: "arn:aws:iam::109909283643:role/RUM-Monitor-eu-west-1-109909283643-4938241521071-Unauth",
      identityPoolId: "eu-west-1:9934149e-8e6f-4a42-be1a-074cb7d8a5a4",
      endpoint: "https://dataplane.rum.eu-west-1.amazonaws.com",
      telemetries: ["performance","errors", ["http", { 
        urlsToInclude: [/^\/api\/.*/, /^\/pictures\/.*/],
        addXRayTraceIdHeader: [/^\/api\/.*/, /^\/pictures\/.*/]
      }]],
      allowCookies: false,
      enableXRay: true
    }
  },
  consent: true,
  gtag: {
    trackingIds: ['AW-11419857985']
  },
  tidio: {
    id: 'y8bs27sbudznhwubxculjpx94gbwfjiz'
  },
  searchByCategory: true
  //tawk: {
  //  propertyId: "642d167d31ebfa0fe7f693de",
  //  widgetId: "1gt81vnem"
  //}
};