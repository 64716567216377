
import { Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()
export class ParsingService {

  constructor(
      protected http: HttpClient) {
  }

  public parse(references: string[]): Observable<void> {
    return this.http.post<void>(`${environment.apiContext}/parsings`, {
      emailReferences: references
    })
  }
  
  public parseAllFailedEmails(): Observable<void> {
    return this.http.post<void>(`${environment.apiContext}/extractions`, {
    })
  }
}

	