import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()
export class BetaSignUpService {

  constructor(
      protected http: HttpClient) {
  }

  public save(email: string, program: string): Observable<void> {
    
    return this.http.post<void>(`${environment.apiContext}/beta-signups`, { email: email, program: program });
  }
}

  