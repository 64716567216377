<span class="search-input">
  <span class="input">
    <form (ngSubmit)="search()" [formGroup]="searchForm">
      
      <mat-form-field style="width: 100%;" appearance="outline">
        <button 
            matPrefix
            mat-icon-button
            type="submit"
            angulartics2On="click" 
            angularticsAction="SearchClick">
            
            <mat-icon fontSet="material-symbols-outlined">search</mat-icon>
        </button>
        <input matInput formControlName="q" i18n-placeholder placeholder="Search for any thing"/>
      </mat-form-field>
    
    </form>
  </span>
  <span *ngIf="showFilterButton" class="filter-button">
    <button (click)="triggerOnFiltersClicked()" 
        mat-icon-button 
        angulartics2On="click"
        angulartics2On="click"  
        angularticsAction="Button Pressed"
        angularticsCategory="Filters"
    >
        
      <mat-icon fontSet="material-symbols-outlined" 
          [matBadge]="numberOfAppliedFilters"
          [matBadgeHidden]="numberOfAppliedFilters === 0"  
          matBadgeColor="primary">filter_alt</mat-icon>
    </button>
  </span>
</span>