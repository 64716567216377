import { Injectable } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import { HttpClient } from '@angular/common/http';
import { Subscription, firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EventEmitter } from '@angular/core';


export interface Consent {
  id: string;
  state: ConsentState;
  timestamp: number;
}

export enum ConsentState {
  GRANTED, DENIED
}

@Injectable()
export class ConsentService {

  private static CONSENT = "listx_consent";
  
  private _onConsent = new EventEmitter();

  constructor(
    private http: HttpClient,
    ) {
  }
  
  getConsentState(): ConsentState | null {
    
    const consent = this.getConsent();
    
    return consent ? consent.state : null;
  }
  
  getConsent(): Consent | undefined {
    
    const storedConsentString = localStorage.getItem(ConsentService.CONSENT);
    
    return storedConsentString ? JSON.parse(storedConsentString) : undefined;
  }
  
  grantConsent() {
    this.storeConsent(ConsentState.GRANTED);
  }
  
  onConsent(callback: (consent: Consent) => void): Subscription {
    console.log("Subscribing for onConsent");
    return this._onConsent.subscribe(callback);
  }
  
  private storeConsent(state: ConsentState) {
    
    const consent = {
      id: uuidv4(),
      state: state,
      timestamp: Date.now()
    } as Consent
    
    localStorage.setItem(ConsentService.CONSENT, JSON.stringify(consent));
    this.createConsentRecord(consent);
    console.log("Firing onConsent");
    this._onConsent.emit(consent);
  }
  
  denyConsent() {
    this.storeConsent(ConsentState.DENIED);
  }
  
  private createConsentRecord(consent: Consent): Promise<void> {
     return firstValueFrom(this.http.post<void>(`${environment.apiContext}/consents`, consent));
  }
}