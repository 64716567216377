import { Component } from "@angular/core";
import { MatBottomSheetRef } from "@angular/material/bottom-sheet";
import { AnalyticsService } from "src/app/_services/analytics.service";
import { ConsentService } from "src/app/_services/consent.service";

@Component({
  selector: 'consent-bottom-sheet',
  templateUrl: './consent.component.html',
})
export class ConsentSheet {
  
  customizeSelection: boolean = false;
  
  constructor(
    private _bottomSheetRef: MatBottomSheetRef<ConsentSheet>,
    private consentService: ConsentService
    ) {
      
    this._bottomSheetRef.disableClose = true;  
  }

  accept() {
    this.consentService.grantConsent();
    this._bottomSheetRef.dismiss();
  }
  
  deny() {
    this.consentService.denyConsent();
    this._bottomSheetRef.dismiss();
  }
}
