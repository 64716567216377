<h1>Cookie Settings</h1>
<p>Are you ok with optional cookies? They let us give you a better experience, improve our products, and assist in our marketing efforts.</p>
<p><a routerLink="/cookie-policy">View our Cookie Policy for more information.</a></p>

<div class="button-panel" style="margin-bottom: 16px">
  <button 
  mat-raised-button
  (click)="accept()" 
  color="accent">ACCEPT</button>
 
  <button 
    mat-stroked-button
    (click)="deny()">DENY</button>
    
</div>
